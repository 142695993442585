<template>
  <layout>
    <template slot="drawer">
      <v-form ref="searchform" v-if="ctx" @submit.prevent="search()">
        <v-row no-gutters class="pt-2 pr-1">
          <v-col cols="12">
            <v-subheader light class="pa-0 mt-5 mb-0 mx-0 form-subheader"
            >Dates de réservation
            </v-subheader
            >
            <v-divider></v-divider>
          </v-col>
          <v-col cols="6" class="pr-1 mb-3">
            <smart-picker
                v-model="ctx.startDateAct"
                :stopDate="ctx.stopDateAct"
                :inputOpts="{
                label: $t('start'),
                clearable: true,
                placeholder: ' ',
              }"
            ></smart-picker>
          </v-col>
          <v-col cols="6" class="pr-1">
            <smart-picker
                v-model="ctx.stopDateAct"
                :startDate="ctx.startDateAct"
                :inputOpts="{
                label: $t('end'),
                clearable: true,
                placeholder: ' ',
              }"
            ></smart-picker>
          </v-col>

        </v-row>

        <smart-btn type="submit" :loading="loading" block primary
        >Rechercher
        </smart-btn
        >
        <smart-btn
            block
            tertiary
            @click.native="
            clearSearchform();

          "
        >Réinitialiser la recherche
        </smart-btn
        >
      </v-form>
    </template>
    <template slot="content">
      <div class="grid ">
        <v-row v-if="data.total.length > 0">
          <v-col class="col-xs-0 col-sm-0 col-md-3 "></v-col>
          <v-col class="col-sm-12 col-md-6 ">
            <div class="d-flex align-center">
              <v-subheader class=" title mr-2">Total</v-subheader>
              <v-chip x-small outlined color="green">
                n-1
              </v-chip>
            </div>
            <v-data-table class="tbl"
                          :headers="headers"
                          :items="data.total"
                          hide-default-footer>
              <template #body="{items}">
                <tbody>
                <tr v-for="(item, idx) in items" :key="idx">
                  <td>
                    {{ item.regionName }}
                  </td>
                  <td class="text-right">
                    <span class="mr-1  font-weight-regular">{{ item.nbActiveAgencies.act }}</span>
                    <span class="caption"
                          :class="item.nbActiveAgencies.act < item.nbActiveAgencies.old ? 'red--text':'green--text'">
                    {{ item.nbActiveAgencies.old }}
                  </span>
                  </td>
                  <td class="text-right">
                    <span class="mr-1  font-weight-regular">{{ item.nbAgencies.act }}</span>
                    <span class="caption"
                          :class="item.nbAgencies.act < item.nbAgencies.old ? 'red--text':'green--text'">
                    {{ item.nbAgencies.old }}
                  </span>
                  </td>
                  <td class="text-right">
                    <span class="mr-1  font-weight-regular">{{ item.nbAgents.act }}</span>
                    <span class="caption" :class="item.nbAgents.act < item.nbAgents.old ? 'red--text':'green--text'">
                    {{ item.nbAgents.old }}
                  </span>
                  </td>
                  <td class="text-right">
                    <span class="mr-1  font-weight-regular">{{ item.nbBookings.act }}</span>
                    <span class="caption"
                          :class="item.nbBookings.act < item.nbBookings.old ? 'red--text':'green--text'">
                    {{ item.nbBookings.old }}
                  </span>
                  </td>
                  <td class="text-right">
                    <span class="mr-1  font-weight-regular">{{ item.totalRevenue.act | currency("EUR") }}</span>
                    <span class="caption"
                          :class="item.totalRevenue.act < item.totalRevenue.old ? 'red--text':'green--text'">
                    {{ item.totalRevenue.old | currency("EUR") }}
                  </span>
                  </td>

                </tr>
                </tbody>
              </template>

            </v-data-table>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="col-xs-0 col-sm-0 col-md-3 "></v-col>
          <v-col class="col-sm-12 col-md-6 ">
            <div class="d-flex align-center">
              <v-subheader class=" title mr-2">Avec Vols</v-subheader>
              <v-chip x-small outlined color="green">
                n-1
              </v-chip>
            </div>
            <v-data-table class="tbl" v-if="data.withFlights != null"
                          :headers="headers"
                          :items="data.withFlights"
                          hide-default-footer>
              <template #body="{items}">
                <tbody>
                <tr v-for="(item, idx) in items" :key="idx">
                  <td>
                    {{ item.regionName }}
                  </td>
                  <td class="text-right">
                    <span class="mr-1  font-weight-regular">{{ item.nbActiveAgencies.act }}</span>
                    <span class="caption"
                          :class="item.nbActiveAgencies.act < item.nbActiveAgencies.old ? 'red--text':'green--text'">
                    {{ item.nbActiveAgencies.old }}
                  </span>
                  </td>
                  <td class="text-right">
                    <span class="mr-1  font-weight-regular">{{ item.nbAgencies.act }}</span>
                    <span class="caption"
                          :class="item.nbAgencies.act < item.nbAgencies.old ? 'red--text':'green--text'">
                    {{ item.nbAgencies.old }}
                  </span>
                  </td>
                  <td class="text-right">
                    <span class="mr-1  font-weight-regular">{{ item.nbAgents.act }}</span>
                    <span class="caption" :class="item.nbAgents.act < item.nbAgents.old ? 'red--text':'green--text'">
                    {{ item.nbAgents.old }}
                  </span>
                  </td>
                  <td class="text-right">
                    <span class="mr-1  font-weight-regular">{{ item.nbBookings.act }}</span>
                    <span class="caption"
                          :class="item.nbBookings.act < item.nbBookings.old ? 'red--text':'green--text'">
                    {{ item.nbBookings.old }}
                  </span>
                  </td>
                  <td class="text-right">
                    <span class="mr-1  font-weight-regular">{{ item.totalRevenue.act | currency("EUR") }}</span>
                    <span class="caption"
                          :class="item.totalRevenue.act < item.totalRevenue.old ? 'red--text':'green--text'">
                    {{ item.totalRevenue.old | currency("EUR") }}
                  </span>
                  </td>

                </tr>
                </tbody>
              </template>

            </v-data-table>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="col-xs-0 col-sm-0 col-md-3 "></v-col>
          <v-col class="col-sm-12 col-md-6 ">
            <div class="d-flex align-center">
              <v-subheader class=" title mr-2">Sans Vols</v-subheader>
              <v-chip x-small outlined color="green">
                n-1
              </v-chip>
            </div>
            <v-data-table class="tbl" v-if="data.noFlights != null"
                          :headers="headers"
                          :items="data.noFlights"
                          hide-default-footer
            >
              <template #body="{items}">
                <tbody>
                <tr v-for="(item, idx) in items" :key="idx" class="">
                  <td>
                    {{ item.regionName }}
                  </td>
                  <td class="text-right">
                    <span class="mr-1  font-weight-regular">{{ item.nbActiveAgencies.act }}</span>
                    <span class="caption"
                          :class="item.nbActiveAgencies.act < item.nbActiveAgencies.old ? 'red--text':'green--text'">
                    {{ item.nbActiveAgencies.old }}
                  </span>
                  </td>
                  <td class="text-right">
                    <span class="mr-1  font-weight-regular">{{ item.nbAgencies.act }}</span>
                    <span class="caption"
                          :class="item.nbAgencies.act < item.nbAgencies.old ? 'red--text':'green--text'">
                    {{ item.nbAgencies.old }}
                  </span>
                  </td>
                  <td class="text-right">
                    <span class="mr-1  font-weight-regular">{{ item.nbAgents.act }}</span>
                    <span class="caption" :class="item.nbAgents.act < item.nbAgents.old ? 'red--text':'green--text'">
                    {{ item.nbAgents.old }}
                  </span>
                  </td>
                  <td class="text-right">
                    <span class="mr-1  font-weight-regular">{{ item.nbBookings.act }}</span>
                    <span class="caption"
                          :class="item.nbBookings.act < item.nbBookings.old ? 'red--text':'green--text'">
                    {{ item.nbBookings.old }}
                  </span>
                  </td>
                  <td class="text-right">
                    <span class="mr-1  font-weight-regular">{{ item.totalRevenue.act | currency("EUR") }}</span>
                    <span class="caption"
                          :class="item.totalRevenue.act < item.totalRevenue.old ? 'red--text':'green--text'">
                    {{ item.totalRevenue.old | currency("EUR") }}
                  </span>
                  </td>

                </tr>
                </tbody>
              </template>

            </v-data-table>
          </v-col>
        </v-row>
      </div>
    </template>
  </layout>
</template>
<script>
import Layout from "@/views/Layout";
import {post} from "@/utils/api";
import SmartPicker from "@/components/commons/SmartPicker.vue";

export default {
  name: 'HlStats',
  components: {SmartPicker, Layout},
  data() {
    return {
      headers: [
        {
          text: "Région",
          value: "regionName",
        },
        {text: "Agences actives", value: "nbActiveAgencies", align: "right"},
        {text: "Agences", value: "nbAgencies", align: "right"},
        {text: "Agents", value: "nbAgents", align: "right"},
        {text: "Bookings", value: "nbBookings", align: "right"},
        {text: "CA", value: "totalRevenue", align: "right"},
      ],
      loading: false,
      ctx: null,
      defaultCtx: {
        startDateAct: this._formatDate(new Date().setFullYear(new Date().getFullYear()-1), "dd/MM/yyyy"),
        stopDateAct: this._nowFormatted("dd/MM/yyyy"),
        startDateOld: null,
        stopDateOld: null,
        destinations: [],
        sites: ['HLagons'],
        commercialIds: [],
        allAgencies: true
      },
      data: {noFlights: [], withFlights: [], total: []}
    }
  },
  created() {
    this.ctx = this.defaultCtx
    this.search()
  },
  methods: {
    search() {
      this.data = {noFlights: [], withFlights: [], total:[]}
      this.loading = true
      const searchForm = {
        ...this.ctx,
        startDateOld: this._formatDate(this._subYears(this._parseDate(this.ctx.startDateAct, "dd/MM/yyyy"), 1), 'yyyy-MM-dd'),
        stopDateOld: this._formatDate(this._subYears(this._parseDate(this.ctx.stopDateAct, "dd/MM/yyyy"), 1), 'yyyy-MM-dd'),
        startDateAct: this._parseAndFormatDate(this.ctx.startDateAct, "dd/MM/yyyy", "yyyy-MM-dd"),
        stopDateAct: this._parseAndFormatDate(this.ctx.stopDateAct, "dd/MM/yyyy", "yyyy-MM-dd"),

      }

      post("/api/stats/hl/regions", searchForm).json(rs => {
        ['act', 'actWithFlights'].forEach(str => {
          const oldStr = str == 'act' ? 'old' : 'oldWithFlights'
          const obj = rs[str].map(act => {
            const oldObj = rs[oldStr].find(old => old.regionName == act.regionName)
            return {
              ...act,
              nbActiveAgencies: {act: act.nbActiveAgencies, old: oldObj.nbActiveAgencies},
              nbAgencies: {act: act.nbAgencies, old: oldObj.nbAgencies},
              nbAgents: {act: act.nbAgents, old: oldObj.nbAgents},
              nbBookings: {act: act.nbBookings, old: oldObj.nbBookings},
              totalRevenue: {act: act.totalRevenue, old: oldObj.totalRevenue},
            }
          })


          this.data[str == 'act' ? 'noFlights' : 'withFlights'].push(...obj)

        })
      }).then(() => {
        this.data.noFlights.forEach(noFlight => {
            const withFlight = this.data.withFlights.find(data => data.regionName == noFlight.regionName)
            const total = {
               ...noFlight,
               nbActiveAgencies: {act: noFlight.nbActiveAgencies.act + withFlight.nbActiveAgencies.act , old: noFlight.nbActiveAgencies.old +withFlight.nbActiveAgencies.old},
               nbAgencies: {act: noFlight.nbAgencies.act + withFlight.nbAgencies.act , old: noFlight.nbAgencies.old +withFlight.nbAgencies.old},
               nbAgents: {act: noFlight.nbAgents.act + withFlight.nbAgents.act , old: noFlight.nbAgents.old +withFlight.nbAgents.old},
               nbBookings: {act: noFlight.nbBookings.act + withFlight.nbBookings.act , old: noFlight.nbBookings.old +withFlight.nbBookings.old},
               totalRevenue: {act: noFlight.totalRevenue.act + withFlight.totalRevenue.act , old: noFlight.totalRevenue.old +withFlight.totalRevenue.old},
             }
             this.data.total.push(total)
          })
      })

      this.loading = false
    },
  },
  clearSearchform() {
    this.ctx = this.defaultCtx
  }
  ,
}
</script>
