<template>
  <v-row justify="space-between">
    <v-col cols="12">
      <div class="text-right mb-2">
        <v-btn href="#" @click="exportCsv('bookingList')" small>
          <v-icon small round>mdi-file-download</v-icon>
          export
        </v-btn>
      </div>
      <v-data-table
          ref="bookingList"
          :loading="listing.loading"
          :headers="listingHeaders"
          :items="listing.bookings"
          :options.sync="listing.pagination"
          :footer-props="{
          'items-per-page-options': listing.rowsPerPageItems,
          'items-per-page-text': 'Lignes par page',
        }"
          item-key="name"
          no-data-text="Il n'y a pas de dossiers disponible"
          class="elevation-1 tbl"
      >
        <template #item="{ item }">
          <tr
              @click.ctrl="rowClicked(item.bookingId, true)"
              @click.meta="rowClicked(item.bookingId, true)"
              @click.exact="rowClicked(item.bookingId)"
          >
            <td
                class="text-left font-weight-regular"
                v-if="getHeader('siteName')"
            >
              <span class="caption grey--text text--darken-1">{{
                  item.bookingId
                }}</span>
              <site-label :sitename="item.siteName"></site-label>
            </td>
            <td class="text-left" v-if="getHeader('bookingDate')">
              <div class="font-weight-regular">
                {{
                  _parseAndFormatDate(
                      item.bookingDate,
                      datePatternConfig.serverLocalDateTime,
                      "dd/MM/yy"
                  )
                }}
              </div>
              <div class="caption grey--text text--darken-1">
                {{
                  _parseAndFormatDate(
                      item.bookingDate,
                      datePatternConfig.serverLocalDateTime,
                      datePatternConfig.fr.time
                  )
                }}
              </div>
            </td>
            <td class="text-left" v-if="getHeader('startDate')">
              <div class="font-weight-regular">
                {{
                  _parseAndFormatDate(
                      item.startDate,
                      datePatternConfig.serverLocalDateTime,
                      "dd/MM/yy"
                  )
                }}
              </div>

              <div class="caption grey--text text--darken-1">
                {{
                  _parseAndFormatDate(
                      item.stopDate,
                      datePatternConfig.serverLocalDateTime,
                      "dd/MM/yy"
                  )
                }}
                • {{ item.duration }}n
              </div>
            </td>

            <td
                class="text-left font-weight-regular"
                v-if="getHeader('hotelWithDesti')"
            >
              <div
                  v-if="item.hotelWithDesti"
                  v-html="renderHotelWithDesti(item.hotelWithDesti)"
              ></div>
            </td>
            <td
                class="text-left"
                v-if="getHeader('bookingStatus')"
                style="min-width: 175px"
            >
              <availability v-model="item.bookingStatus" kind="BookingStatus">
              </availability>
              <availability
                  v-model="item.supplierStatus"
                  kind="SupplierStatus"
                  v-if="item.supplierStatus"
              />
              <div>
                <v-tooltip top v-for="(tag, index) in item.tags" :key="index">
                  <template #activator="{ on }">
                    <v-chip v-on="on" small label class="pink white--text mx-1">
                      <v-icon left dark class="mr-0 pr-1">mdi-tag</v-icon>
                      {{ tag }}
                    </v-chip>
                  </template>
                  {{ tag }}
                </v-tooltip>
              </div>
            </td>

          </tr>
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>
import Layout from "@/views/Layout";
import {post} from "@/utils/api";

import WidgetBookingSize from "@/components/commons/widgets/WidgetBookingSize";
import WidgetBookingPassenger from "@/components/commons/widgets/WidgetBookingPassenger";
import WidgetBookingCa from "@/components/commons/widgets/WidgetBookingCa";
import WidgetBookingResume from "@/components/commons/widgets/WidgetBookingResume";
import Availability from "@/components/commons/Availability";
import SiteLabel from "./SiteLabel";
import ExportMixin from "@/components/mixins/ExportMixin";
import {isEqual, parseISO} from "date-fns";

export default {
  name: "BookingsListLiteTable",
  components: {
    SiteLabel,
    Availability,
  },
  mixins: [ExportMixin],
  props: {
    rowClickFn: {
      type: Function,
      required: false,
    },
    sortKey: {type: String, default: null},
    sortDesc: {type: Boolean, default: false}
  },
  data() {
    return {
      sortBy: "name",
      listing: {
        loading: false,
        rowsPerPageItems: [{text: "Tous", value: -1}, 50, 200],
        pagination: {
          options: {
            page: 1,
            itemsPerPage: 200,
            sortBy: [],
            sortDesc: [false],
          },
        },
        bookings: [],
      },
    };
  },
  created() {
  },
  watch: {},
  computed: {
    listingHeaders() {
      const filterHeaders = (allowedRoles) =>
          this.userRolesIds.some((r) => allowedRoles.includes(r));

      const defaultRolesAccess = [
        this.roles.admin,
        this.roles.onplaceagent,
        this.roles.hlItaly,
      ];
      const headers = [
        {
          text: "SITE",
          value: "siteName",
          align: "left",
          allowedRoles: defaultRolesAccess,
        },
        {
          text: "RÉSA",
          value: "bookingDate",
          align: "left",
          allowedRoles: defaultRolesAccess,
          sort: this.sortByDateTime,
        },
        {
          text: "SÉJOUR",
          value: "startDate",
          align: "left",
          width: "10%",
          allowedRoles: defaultRolesAccess,
          sort: this.sortByDateTime,
        },
        {
          text: "DESTINATION",
          value: "hotelWithDesti",
          align: "left",
          sortable: false,
          allowedRoles: defaultRolesAccess,
        },
        {
          text: "STATUTS",
          value: "bookingStatus",
          align: "left",
          sortable: false,
          allowedRoles: defaultRolesAccess,
        },
      ];

      const filteredHeaders = headers.filter((item, index) => {
        return filterHeaders(item.allowedRoles);
      });

      return filteredHeaders;
    },
  },
  methods: {
    getHeader(value) {
      return this.listingHeaders.find((x) => x.value === value) != null;
    },
    load(url, search) {

      this.listing.loading = true;
      this.$emit("loading", this.listing.loading);
      post(url, search).json((res) => {
        this.listing.loading = false;
        this.$emit("loading", this.listing.loading);
        if (this.sortKey != null) {
          res = res.sort((a, b) => {
            const aDate = this._parseDateTime(a[this.sortKey], "dd/MM/yyyy HH:mm:ss")
            const bDate = this._parseDateTime(b[this.sortKey], "dd/MM/yyyy HH:mm:ss")

            if (this._isBefore(aDate, bDate)) {
              return 1
            } else if (aDate == bDate) {
              return 0
            } else {
              return -1
            }
          })
        }
        if (this.sortDesc) {
          res = res.reverse()
        }
        this.listing.bookings = res;
      });
    },
    renderHotelWithDesti(hotelWithDesti) {
      return hotelWithDesti
          .map((hotel) => {
            return `<div class="text-xs-left font-weight-regular">${hotel[0]}: ${hotel[1]}</div>`;
          })
          .join("");
    },

    rowClicked(bookingId, newTab) {
      this.rowClickFn
          ? this.rowClickFn(bookingId)
          : this.goto(bookingId, newTab);
    },
    goto(bookingId, newTab) {
      if (newTab) {
        let route = this.$router.resolve({
          name: "Booking",
          params: {id: bookingId},
        });
        window.open(route.href, "_blank");
        return false;
      } else {
        this.$router.push({name: "Booking", params: {id: bookingId}});
      }
    },
    sortByDateTime(a, b) {
      return this._sortDates(a, b, this.datePatternConfig.serverLocalDateTime);
    },
  },
};
</script>

<style scoped></style>
