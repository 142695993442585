<template>
  <div>
    <div id="content" class="">
      <grid-layout v-if="items.length == 0 || widgetList.length > 0"
                   ref="gridlayout"
                   :class="edit ? 'grid w-full h-screen' : ''"
                   :layout.sync="widgetList"
                   :is-draggable="edit"
                   :is-resizable="edit"
                   :is-mirrored="false"
                   :vertical-compact="compacted"
                   :prevent-collision="true"
                   :responsive="!edit"
                   :col-num="12"
                   :row-height="110"
                   :margin="[10, 10]"
                   :use-css-transforms="true"
      >
        <grid-item v-for="(item,ind) in widgetList"
                   :x="item.x"
                   :y="item.y"
                   :w="item.w"
                   :h="item.h"
                   :i="item.id  != null  ? item.id : item.i"
                   :minW="item.widget.minWidth"
                   :maxW="item.widget.maxWidth"
                   :minH="item.widget.minHeight"
                   :maxH="item.widget.maxHeight"
                   :key="`${item.widget.id}_${ind}`"
                   :class="edit ? 'editable':''"
                   class="rel">

          <div class="widget-icons">
            <v-tooltip right v-if="widgetIsCustom(item) && item.idWidget != 39">
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs"
                        v-on="on" small color="grey" class="mr-2">sync_problem
                </v-icon>

              </template>
              <div class="d-flex flex-column">
                Les valeurs de ce widget sont personnalisées donc elles ne sont pas impactés par les filtres de
                recherche
              </div>
            </v-tooltip>
            <v-icon v-if="edit" @click.native="configWidget(item)" class="pointer mr-2" small color="grey">settings
            </v-icon>
            <v-icon v-if="edit" @click.native="removeWidget(item)" class="pointer mr-2" small color="grey">delete
            </v-icon>
            <v-tooltip left v-else-if="getTooltipText(item) != null && item.idWidget != 39">
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs"
                        v-on="on" small color="grey" class="mr-2">info
                </v-icon>
              </template>
              <div class="d-flex flex-column" v-html="getTooltipText(item)">

              </div>
            </v-tooltip>
          </div>
          <component :is="item.widget.name" :form="form" v-if="!hiddenWidgets.includes(item.widget.name)"
                     :conf="getConf(item)" @openConfig="configWidget(item)"></component>
        </grid-item>
      </grid-layout>
      <smart-dialog id="confDialog" ref="confDialog" :title="`Configuration ${dialogItem.widget.title}`"
                    v-if="dialogItem != null" :value="dialogOpened" @close="dialogOpened = false">
        <template>
          <div class="pa-4 d-flex flex-column">
            <textarea id="jsonValue" rows="25" cols="50" class="border-thin mb-4"
                      v-model="confStringified"></textarea>
            <div class="d-flex flex-row">
              <smart-btn type="button" small primary @click.native="prettyPrint()" class="mr-auto">Beautify Json
              </smart-btn>
              <span v-if="jsonError" class="red--text mr-auto">Erreur de syntax dans le JSON</span>
              <smart-btn type="button" small secondary @click.native="cancelConf()" class="mr-4">Annuler</smart-btn>
              <smart-btn type="button" small primary @click.native="validConf()" class="">Valider</smart-btn>
            </div>
          </div>
        </template>
      </smart-dialog>
    </div>
    <v-navigation-drawer
        :mobile-breakpoint="10"
        app
        v-model="drawer"
        right
        width="300"
    >
      <v-card
          width="300"
          class="ml-auto"
          style="overflow: hidden"
      >
        <div class="pa-4">
          <h2 class="text-h6">
            Widgets disponibles
          </h2>
          <span>
            Drag & drop
          </span>
        </div>

        <v-divider></v-divider>

        <v-list
            dense
            nav
            style="overflow:scroll; height:100%"
        >
          <v-list-item
              v-for="(widget,i) in widgetLib" :key="i"
              class="list-lib-widget droppable-element "
              @dragstart="dragStart(widget)" @drag="drag" @dragend="dragend"
              draggable="true"
              unselectable="on"
          >
            <v-list-item-icon>
              <v-icon>{{ getType(widget.typeWidget) }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ widget.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card>
    </v-navigation-drawer>
  </div>
</template>
<script>
import Layout from "@/views/Layout.vue";
import VueGridLayout from 'vue-grid-layout';
import MarginTile from "@/components/commons/widgets/tiles/MarginTile.vue";
import NbBookingTile from "@/components/commons/widgets/tiles/NbBookingTile.vue";
import PurchasesTile from "@/components/commons/widgets/tiles/PurchasesTile.vue";
import {get, post} from "@/utils/api";
import NbPaxTile from "@/components/commons/widgets/tiles/NbPaxTile.vue";
import AveDayDepartureTile from "@/components/commons/widgets/tiles/AveDayDepartureTile.vue";
import AverageBookingPriceTile from "@/components/commons/widgets/tiles/AverageBookingPriceTile.vue";
import RatioHotelFlightTile from "@/components/commons/widgets/tiles/RatioHotelFlightTile.vue";
import NbBookingRefusedTile from "@/components/commons/widgets/tiles/NbBookingRefusedTile.vue";
import OptionsComfimedRatioTile from "@/components/commons/widgets/tiles/OptionsComfimedRatioTile.vue";
import BookingPerAirportTable from "@/components/commons/widgets/datatables/BookingPerAirportTable.vue";
import ErrorPaymentCauseTable from "@/components/commons/widgets/datatables/ErrorPaymentCauseTable.vue";
import TransfersTable from "@/components/commons/widgets/datatables/TransfersTable.vue";
import AirlinesTable from "@/components/commons/widgets/datatables/AirlinesTable.vue";
import HotelsTable from "@/components/commons/widgets/datatables/HotelsTable.vue";
import SimpleDateCAChart from "@/components/commons/widgets/charts/SimpleDateCAChart.vue";
import DonutStatPerSiteChart from "@/components/commons/widgets/charts/DonutStatPerSiteChart.vue";
import BubbleCADayDepartureChart from "@/components/commons/widgets/charts/BubbleCADayDepartureChart.vue";
import BarLeftPayChart from "@/components/commons/widgets/charts/BarLeftPayChart.vue";
import DistributionQuotationChart from "@/components/commons/widgets/charts/DistributionQuotationChart.vue";
import HotelFlightsCombinedChart from "@/components/commons/widgets/charts/HotelFlightsCombinedChart.vue";
import SalesPerHourChart from "@/components/commons/widgets/charts/SalesPerHourChart.vue";
import {EventBus} from "@/components/commons/event-bus";
import SalesTile from "@/components/commons/widgets/tiles/SalesTile.vue";
import WidgetCA from "@/components/commons/widgets/datatables/WidgetCA.vue";
import StockStatusFutureChart from "@/components/commons/widgets/charts/StockStatusFutureChart.vue";
import MarginBySiteByDestiTable from "@/components/commons/widgets/datatables/MarginBySiteByDestiTable.vue";
import PodiumCA from "@/components/commons/widgets/datatables/PodiumCA.vue";
import SalesByNetworkTable from "@/components/commons/widgets/datatables/SalesByNetworkTable.vue";
import ContactedAgencyTable from "@/components/commons/widgets/datatables/ContactedAgencyTable.vue";
import CommercialLogDemoTable from "@/components/commons/widgets/datatables/CommercialLogDemoTable.vue";
import EvolutionLoginsTable from "@/components/commons/widgets/datatables/EvolutionLoginsTable.vue";
import HlLoginCount from "@/components/commons/widgets/tiles/HlLoginCount.vue";
import HLIncentiveTile from "@/components/commons/widgets/tiles/HLIncentiveGiftedTile.vue";
import HLIncentiveNotGiftedTile from "@/components/commons/widgets/tiles/HLIncentiveNotGiftedTile.vue";
import RateCartToBookChart from "@/components/commons/widgets/charts/RateCartToBookChart.vue";
import RateFlightInBookingChart from "@/components/commons/widgets/charts/RateFlightInBookingChart.vue";
import IframeTemplate from "@/components/commons/widgets/tiles/IframeTemplate.vue";
import CallPerSiteChart from "@/components/commons/widgets/charts/CallPerSiteChart.vue";
import CAQuotationChart from "@/components/commons/widgets/charts/CAQuotationChart.vue";
import DonutStatPerVirtualSiteChart from "@/components/commons/widgets/charts/DonutStatPerVirtualSiteChart.vue";
import PercentRefusedByDemandPrestaTile from "@/components/commons/widgets/tiles/PercentRefusedByDemandPrestaTile.vue";
import SalesMatrix from "@/components/commons/widgets/datatables/SalesMatrix.vue";
import RemainingAmountMatrix from "@/components/commons/widgets/datatables/RemainingAmountMatrix.vue";
import CountLeadChart from "@/components/commons/widgets/charts/CountLeadChart.vue";
import ConvertedLeadBySourceTable from "@/components/commons/widgets/datatables/ConvertedLeadBySourceTable.vue";

let mouseXY = {"x": null, "y": null};
let DragPos = {"x": null, "y": null, "w": 1, "h": 1, "i": null};
export default {
  components: {
    Layout,
    WidgetCA,
    SalesTile,
    MarginTile,
    NbBookingTile,
    PurchasesTile,
    NbPaxTile,
    CountLeadChart,
    AveDayDepartureTile,
    AverageBookingPriceTile,
    RatioHotelFlightTile,
    NbBookingRefusedTile,
    OptionsComfimedRatioTile,
    BookingPerAirportTable,
    ErrorPaymentCauseTable,
    TransfersTable, AirlinesTable, HotelsTable,
    HlLoginCount, HLIncentiveTile, HLIncentiveNotGiftedTile,
    SimpleDateCAChart,
    DonutStatPerSiteChart,
    DonutStatPerVirtualSiteChart,
    BubbleCADayDepartureChart,
    BarLeftPayChart,
    DistributionQuotationChart,
    HotelFlightsCombinedChart,
    SalesPerHourChart,
    StockStatusFutureChart,
    MarginBySiteByDestiTable,
    PodiumCA,
    SalesByNetworkTable,
    ContactedAgencyTable,
    CommercialLogDemoTable,
    EvolutionLoginsTable,
    RateCartToBookChart,
    RateFlightInBookingChart,
    IframeTemplate,
    CallPerSiteChart,
    CAQuotationChart,
    PercentRefusedByDemandPrestaTile,
    RemainingAmountMatrix,
    SalesMatrix,
    ConvertedLeadBySourceTable,
    GridLayout: VueGridLayout.GridLayout,
    GridItem: VueGridLayout.GridItem
  },
  props: {
    items: {type: Array, required: true},
    form: {type: Object, required: true},
  },
  data() {
    return {
      dialogOpened: false,
      dialogItem: null,
      confStringified: null,
      compacted: true,
      addWidget: true,
      edit: false,
      widgetLib: [],
      currentmove: null,
      widgetList: [],
      drawer: false,
      hiddenWidgets: [
        /* 'BarLeftPayChart'*/
      ],
      jsonError: false,
    }
  },
  watch: {
    edit: {
      deep: true,
      handler() {
      }
    },
    items: {
      deep: true,
      handler() {
      },
    },
  },
  mounted() {
    this.init();
    document.addEventListener("dragover", function (e) {
      mouseXY.x = e.clientX;
      mouseXY.y = e.clientY;
    }, false);
  },
  created() {
    const self = this
    this.$nextTick(() => {
      EventBus.$on('editDashboard', (rs) => {
        self.saveEdit = rs.save
        self.toggleEdit(rs.save)
      })
    })
  },

  destroyed() {
    EventBus.$off('editDashboard')
  },
  methods: {
    init() {
      EventBus.$on('editDashboard', () => {
        this.drawer = !this.drawer
      })
      get(`/api/search/widgets/all`).json(resp => {
        this.widgetLib = resp.sort((a, b) => {
          return a.title.localeCompare(b.title)
        }).filter(item => !this.hiddenWidgets.includes(item.name))
        this.widgetList = this.items.filter(item => !this.hiddenWidgets.includes(item.widget.name));

      })
    },
    toggleEdit(toSave) {
      if (this.edit && toSave) {
        this.updateDashboard()
      }
      this.edit = !this.edit
      EventBus.$emit('editMode', this.edit)
    },
    updateDashboard() {
      if (this.saveEdit && !this._isMobile()) {
        const path = window.location.pathname
        this.widgetList.forEach((wdgt) => {
          if(wdgt.config.chartOptions != null){
            wdgt.config.chartOptions.annotations = {points:[],xaxis:[],yaxis:[]}
          }
          wdgt.path = path
        })

        post(`/api/widget/dashboard/save?path=${path}`, this.widgetList, {"Content-Type": "application/json"}).json(() => {
          EventBus.$emit('getUpdateWidget', {})
          this.refreshWidgets()
        })
      }
    },
    refreshWidgets() {
      const path = window.location.pathname
      const url = `/api/search/widgets/user/${this.currentUser.id}?path=${path}`
      get(url).json(r => {
        this.widgetList = []
        r.forEach((wdgt) => {
          Object.defineProperty(wdgt, "i",
              Object.getOwnPropertyDescriptor(wdgt, "id"));
        })
        this.widgetList = r
      })
    },
    removeWidget(widget) {
      const ind = this.widgetList.indexOf(widget)
      this.widgetList.splice(ind, 1)
    },
    configWidget(item) {
      this.confStringified = JSON.stringify(item.config)
      this.dialogOpened = true
      this.dialogItem = item
    },
    getConf(item) {
      return item.config != null ? item.config : item.widget.defaultConfig
    },
    widgetIsCustom(item) {
      if (item.config == null) {
        return false
      }
      let tempConfig = JSON.parse(JSON.stringify((item.config)))
      delete tempConfig.chartOptions
      delete tempConfig.chartOptions2
      delete tempConfig.chartOptions3
      return JSON.stringify(tempConfig) !== JSON.stringify(item.widget.defaultConfig)
    },
    prettyPrint() {
      const ugly = document.getElementById('jsonValue').value;
      const obj = ugly.includes("vHtml") ? JSON.parse(this.fixJson(ugly)) : JSON.parse(ugly);
      document.getElementById('jsonValue').value = JSON.stringify(obj, undefined, 4);
    },
    cancelConf() {
      this.dialogOpened = false
      this.$refs.confDialog.close()
    },
    validConf() {
      if (this.confStringified.includes("vHtml")) {
        this.confStringified = this.fixJson(this.confStringified)
      }
      try {
        this.dialogItem.options.annotations
        this.dialogItem.config = JSON.parse(this.confStringified)
        this.jsonError = false
        this.dialogOpened = false
        this.$refs.confDialog.close()
      } catch {
        this.jsonError = true
      }
    },
    fixJson(string) {
      return string.split("vHtml")
          .map((x, i) => i == 1 ? x.replaceAll("\n", "").replaceAll(/\s([":])/g, '"').replaceAll(/(?<![:}])"(?![":}])(?!["\n])/g, "'") : x)
          .join("vHtml")
    },
    getType(type) {
      if (type) {
        switch (type) {
          case 'tile':
            return 'score'
          case 'chart':
            return 'analytics'
          case 'datatable':
            return 'article'
        }
      }
    },

    getTooltipText(item) {
      if (item.idWidget == 29 || item.idWidget == 26 || item.widget.name == "SimpleDateCAChart" || item.widget.name == "WidgetCA" || item.config == null) {
        return null
      }
      let periods = {
        act: {type: null, start: null, stop: null, dayOffset: null},
        old: {type: null, start: null, stop: null, dayOffset: null}
      }
      if (item.config.data.act.type != null || item.config.data.old.type != null) {
        periods = {act: this._calculateDates(item.config.data.act), old: this._calculateDates(item.config.data.old)}

        if (periods.act?.start == null) {
          periods.act.start = this._formatDate(new Date(this.form.startDateAct), "dd MMM yyyy")
          periods.act.stop = this._formatDate(new Date(this.form.stopDateAct), "dd MMM yyyy")
        }

        if (periods.old?.start == null) {
          periods.old.start = this._subYears(this._parseDate(periods.act.start, "yyyy-MM-dd"), 1)
          periods.old.stop = this._subYears(this._parseDate(periods.act.stop, "yyyy-MM-dd"), 1)
        }

        if (item.config.data.act.type != null && periods.act?.start === periods.act?.stop) {
          periods.act.start = this._formatDate(new Date(periods.act.start), "dd MMM yyyy")
          periods.act.stop = this._formatDate(new Date(periods.act.stop), "dd MMM yyyy")
          periods.old.start = this._formatDate(new Date(periods.old.start), "dd MMM yyyy")
          periods.old.stop = this._formatDate(new Date(periods.old.stop), "dd MMM yyyy")
          return `
        <span>Le <strong>${periods.act.start}</strong> </span>
        <span>comparé</span>
         <span>au <strong>${periods.old.start}</strong></span>`

        } else if (!item.widget.comparable) {
          return `<span>Du <strong>${periods.act.start}</strong> au <strong>${periods.act.stop}</strong></span>`
        } else {
          periods.act.start = this._formatDate(new Date(periods.act.start), "dd MMM yyyy")
          periods.act.stop = this._formatDate(new Date(periods.act.stop), "dd MMM yyyy")
          periods.old.start = this._formatDate(new Date(periods.old.start), "dd MMM yyyy")
          periods.old.stop = this._formatDate(new Date(periods.old.stop), "dd MMM yyyy")

          return `
        <span>Du <strong>${periods.act.start}</strong> au <strong>${periods.act.stop}</strong></span>
        <span>comparé à la période</span>
         <span>du <strong>${periods.old.start}</strong> au <strong>${periods.old.stop}</strong></span>`
        }

      } else {
        if (!item.widget.comparable) {
          return `<span>Du <strong>${this._formatDate(new Date(this.form.startDateAct), "dd MMM yyyy")}</strong> au <strong>${this._formatDate(new Date(this.form.stopDateAct), "dd MMM yyyy")}</strong></span>`
        } else {
          let actStart = this._formatDate(new Date(this.form.startDateAct), "dd MMM yyyy");
          let actStop = this._formatDate(new Date(this.form.stopDateAct), "dd MMM yyyy");
          let oldStart = this._formatDate(new Date(this.form.startDateOld), "dd MMM yyyy");
          let oldStop = this._formatDate(new Date(this.form.stopDateOld), "dd MMM yyyy");

          return `<span>Du <strong>${actStart}</strong> au <strong>${actStop}</strong></span>
        <span>comparé à la période</span>
         <span>du <strong>${oldStart}</strong> au <strong>${oldStop}</strong></span>`
        }
      }
    },
    dragStart(elem) {
      this.currentmove = elem
      if (this.widgetList.length === 0) {
        this.widgetList.push({
          x: (this.widgetList.length * 2) % (this.colNum || 12),
          y: this.widgetList.length + (this.colNum || 12),
          w: this.currentmove.defaultWidth,
          h: this.currentmove.defaultHeight,
          i: 'drop',
          widget: this.currentmove
        });
      }
    },
    drag: function (e) {
      let parentRect = document.getElementById('content').getBoundingClientRect();
      let mouseInGrid = false;
      if (((mouseXY.x > parentRect.left)
              && (mouseXY.x < parentRect.right))
          && ((mouseXY.y > parentRect.top) && (mouseXY.y < parentRect.bottom))
      ) {
        mouseInGrid = true;
      }
      if (mouseInGrid === true && (this.widgetList.findIndex(item => item.i === 'drop')) === -1) {
        this.widgetList.push({
          x: (this.widgetList.length * 2) % (this.colNum || 12),
          y: this.widgetList.length + (this.colNum || 12),
          w: this.currentmove.defaultWidth,
          h: this.currentmove.defaultHeight,
          i: 'drop',
          widget: this.currentmove
        });
      }
      let index = this.widgetList.findIndex(item => item.i === 'drop');
      if (index !== -1) {
        let el = this.$refs.gridlayout.$children[index];
        el.dragging = {"top": mouseXY.y - parentRect.top, "left": mouseXY.x - parentRect.left};
        let new_pos = el.calcXY(mouseXY.y - parentRect.top, mouseXY.x - parentRect.left);

        if (mouseInGrid === true) {
          this.$refs.gridlayout.dragEvent('dragstart', 'drop', new_pos.x, new_pos.y, this.currentmove.defaultHeight, this.currentmove.defaultWidth);
          DragPos.i = String(index);
          DragPos.x = this.widgetList[index].x;
          DragPos.y = this.widgetList[index].y;
        }
        if (mouseInGrid === false) {
          this.$refs.gridlayout.dragEvent('dragend', 'drop', new_pos.x, new_pos.y, 1, 1);
          this.widgetList = this.widgetList.filter(obj => obj.i !== 'drop');
        }
      }
    },
    dragend: function (e) {
      let parentRect = document.getElementById('content').getBoundingClientRect();
      let mouseInGrid = false;
      if (((mouseXY.x > parentRect.left) && (mouseXY.x < parentRect.right)) && ((mouseXY.y > parentRect.top) && (mouseXY.y < parentRect.bottom))) {
        mouseInGrid = true;
      }
      if (mouseInGrid === true) {
        this.$refs.gridlayout.dragEvent('dragend', 'drop', DragPos.x, DragPos.y, 1, 1);
        this.widgetList = this.widgetList.filter(obj => obj.i !== 'drop');
        this.widgetList.push({
          idWidget: this.currentmove.id,
          idUser: this.currentUser.id,
          x: DragPos.x,
          y: DragPos.y,
          w: this.currentmove.defaultWidth,
          h: this.currentmove.defaultHeight,
          i: `${this.currentmove.id}_${this.widgetList.length}`,
          widget: this.currentmove,
          config: this.currentmove.defaultConfig,
        });
        this.$refs.gridlayout.dragEvent('dragend', DragPos.i, DragPos.x, DragPos.y, 1, 1);
        try {
          this.$refs.gridlayout.$children[this.widgetList.length].$refs.item.style.display = "block";
          //this.updateDashboard()
        } catch {
          console.error("Error while dropping widget !")
        }
      }
    },
  }
  ,

}
</script>
<style scoped>
.editable {
  border: grey dotted 2px;
}

.border-thin {
  border: black solid 1px;
}

.movable {
  cursor: move;
}

.widget-lib {
  border: grey dashed 1px;
  cursor: move;
}

.rel {
  position: relative;
}

.abs {
  position: absolute;
}

.widget-icons {
  position: absolute;
  z-index: 10;
  right: 0;
}

.icons-left {
  position: absolute;
  z-index: 10;
  left: 0;
}

.pointer {
  cursor: pointer;
}

.vue-grid-layout {
  min-height: 1200px;
}

.grid::before {
  content: '';
  background-size: calc(calc(100% - 10px) / 12) 120px;
  background-image: linear-gradient(
      to right,
      lightgrey 1px,
      transparent 1px
  ),
  linear-gradient(to bottom, lightgrey 1px, transparent 1px);
  height: 100%;
  width: 100%;
  position: absolute;
  background-repeat: repeat;
  margin: 5px;
}

.list-lib-widget:hover {
  background-color: gainsboro;
  cursor: move;

}

</style>

