<template>
  <collapse-panel :collapsable="false" :loading="loadingDetail">
    <div slot="title">
      <v-row align="center">
        <v-col>
          <span class="grey--text text--darken-3 pb-0">{{ _parseAndFormatLocalDateTime(booking.startDate) }} -
            {{ _parseAndFormatLocalDateTime(booking.stopDate) }}</span>
          <span class="caption grey--text lighten-1">(j{{ _durationBeforeStart(booking.startDate) }})</span>
        </v-col>

        <v-col class="text-right">
          <v-tooltip top v-if="booking.optionDate">
            <template #activator="{ on }">
              <v-chip v-on="on" small label>
                <v-icon small class="mr-1">mdi-clock-outline</v-icon>
                {{ booking.optionDate }}
              </v-chip>
            </template>
            Date d'expiration de l'option
          </v-tooltip>

          <availability v-model="booking.status" kind="BookingStatus" />
          <availability v-model="booking.supplierStatus" kind="SupplierStatus" />
        </v-col>
      </v-row>
    </div>

    <div slot="header">
      <v-row dense>
        <v-col cols="6">
          <div>
            <span class="caption grey--text">#</span>
            <span class="subheading font-weight-medium">{{ booking.id }}</span>
            <span class="subheading font-italic grey--text" v-if="booking.externalRef">(koedia: {{ booking.externalRef
              }})</span>
            <span class="subheading font-italic grey--text text--darken-1">{{
              booking.name
            }}</span>
          </div>
          <div class="caption">
            {{
              destinationResume(
                booking.prestations.filter((p) => p.kind === "RoomPrestation")
              )
            }}
          </div>
        </v-col>
        <v-col cols="6" class="align-self-end text-right">
          <div v-if="guests.length > 0">
            {{
              $tc("Adult-count", countAdult(guests), {
                size: countAdult(guests),
              })
            }}
            <span v-if="countChild(guests) > 0">•
              {{
                $tc("Child-count", countChild(guests), {
                  size: countChild(guests),
                })
              }}</span>
            <span v-if="countChild(guests) > 0" v-for="(guest, guestIdx) in guests" :key="'ageguest' + guestIdx"
              class="caption grey--text">
              <span v-if="guestIdx == 0">(</span>
              <span v-if="countChild(guests) > 0 && guest.ageClassType !== 'Adult'">{{ _age(guest.birthDate)
                }}ans</span>
              <span v-if="guestIdx + 1 == guests.length">)</span>
            </span>
            <v-btn icon small @click="guestsOpen = !guestsOpen">
              <v-icon small color="grey">{{
                guestsOpen ? "mdi-eye-off" : "mdi-eye"
              }}
              </v-icon>
            </v-btn>
          </div>
        </v-col>
        <v-col class="body-2">
          <div>Créé le {{ booking.creationDate }}</div>
          <div v-if="booking.status === 'booked'">
            Réservé le {{ booking.bookingDate }}
          </div>
          <div v-if="booking.status === 'cancelled'">
            Annulé le {{ booking.cancelledDate }}
          </div>
        </v-col>
        <v-col cols="12">
          <label class="body-2 font-weight-medium">Remarque client
            <v-btn icon x-small class="mr-5" @click="
              dialogs.remarks = remarks;
            dialogs.clientRemarks = true;
            ">
              <v-icon x-small>edit</v-icon>
            </v-btn>
          </label>
          <span class="body-2">{{ remarks }}</span>
        </v-col>
        <v-col cols="12">
          <v-divider class="mb-2" />

          <v-row>
            <v-col dense cols="9">
              <v-tooltip v-for="(t, i) in tags" :key="i" top>
                <template #activator="{ on }">
                  <v-chip v-on="on" v-if="t.code === 'priceClassType'" label small :id="'chip-' + i"
                    :outlined="!isBadPriceClass" :color="isBadPriceClass ? 'red accent-1' : 'grey lighten-2'"
                    class="text--lighten-1 mx-1 caption">{{ t.value }}
                  </v-chip>
                  <v-chip v-on="on" v-else label small outlined :id="'chip-' + i" color="grey lighten-2"
                    class="text--lighten-1 mx-1 caption">{{ t.value }}
                  </v-chip>
                </template>
                <span v-if="
                  t.code === 'priceClassType' && isBadPriceClass && hasFlight
                ">
                  <v-icon small color="red">mdi-alert-circle</v-icon>
                  {{ t.name }} sans vol contient une prestation de type vol
                </span>
                <span v-else-if="
                  t.code === 'priceClassType' && isBadPriceClass && !hasFlight
                ">
                  <v-icon small color="red">mdi-alert-circle</v-icon>
                  {{ t.name }} avec vol ne contient pas de prestation de type
                  vol
                </span>
                <span v-else>{{ t.name }}</span>
              </v-tooltip>
              <v-tooltip top>
                <template #activator="{ on }">
                  <v-chip v-on="on" label small outlined class="grey lighten-2 text--lighten-2 mx-1 caption"
                    @click.native="browserInfo()">...
                  </v-chip>
                </template>
                Informations navigateur client
              </v-tooltip>
            </v-col>
            <v-col dense cols="3" class="text-right">
              <v-btn small text class="grey--text caption p-1" outlined @click="openGmail(booking.customer.email)">
                <v-icon small class="mr-1">mdi-gmail</v-icon>
                <span v-if="booking.balanceDate" class="text-capitalize">Client</span>
              </v-btn>
              <v-btn small text class="grey--text caption p-1 ml-1" outlined @click="openGmail(booking.id)">
                <v-icon small class="mr-1">mdi-gmail</v-icon>
                <span v-if="booking.balanceDate" class="text-capitalize">Réf.</span>
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" v-if="booking.tags.length > 0">
          <v-tooltip top v-for="(tag, index) in booking.tags" :key="index">
            <template #activator="{ on }">
              <v-chip v-on="on" small label class="pink white--text mx-1">
                <v-icon left dark class="mr-0 pr-1">mdi-tag</v-icon>
                {{ tag }}
              </v-chip>
            </template>
            {{ tag }}
          </v-tooltip>
        </v-col>
        <v-col v-if="guests.length == 0" class="text-center xs">
          <smart-btn secondary @click.native="editGuestDialog = true">Ajouter des voyageurs
          </smart-btn>
        </v-col>
      </v-row>
    </div>

    <template slot="content">
      <v-row v-show="guestsOpen">
        <v-col cols="12">
          <v-divider />
        </v-col>
        <v-col cols="12">
          <v-data-table :headers="[
            {
              text: '#',
              sortable: false,
              width: 30,
              align: 'left',
              value: 'id',
            },
            { text: 'Nom', sortable: false, value: 'lastName' },
            { text: 'Classe d\'age', sortable: false, value: 'ageClassType' },
            { text: 'Prix', sortable: false, value: 'price' },
            { text: 'Prix d\'achat', sortable: false, value: 'purchase' },
            { text: '', sortable: false, value: 'edit' },
          ]" :options.sync="pagination" :footer-props="{
              'items-per-page-options': pagination.rowsPerPageItems,
              'items-per-page-text': 'Lignes par page',
            }" :items="guests" hide-default-footer dense class="elevation-1">
            <template #body="{ items }">
              <tbody>
                <tr v-for="(item, index) in items" :key="index">
                  <td class="pa-1">{{ item.id }}</td>
                  <td>{{ item.firstName }} {{ item.lastName }}</td>
                  <td style="width: 100px">
                    {{ item.ageClassType }}
                    <div v-if="item.birthDate">({{ item.birthDate }})</div>
                  </td>
                  <td style="width: 100px">
                    {{ item.price | currency(item.currency) }}
                  </td>
                  <td style="width: 100px">
                    {{ item.euroPurchase | currency("EUR") }}
                  </td>

                  <td style="width: 40px; padding: 5px; text-align: center">
                      <v-menu bottom>
                        <template #activator="{ on }">
                          <v-btn v-on="on" icon small>
                            <v-icon>more_vert</v-icon>
                          </v-btn>
                        </template>
                        <v-list dense>
                          <v-list-item :disabled="documents.prestaPnr.length == 0" @click="openDocumentDialog(item)">
                            <v-list-item-title>
                              <v-icon small>mdi-file-document-box-plus
                              </v-icon>
                              Ajouter des documents
                            </v-list-item-title>
                          </v-list-item>
                        <v-list-item @click="showMergeGuest(item.id)">
                          <v-list-item-title>
                            <v-icon small style="transform: rotate(-90deg)">mdi-source-fork
                            </v-icon>
                            Fusionner avec un autre passager
                          </v-list-item-title>
                        </v-list-item>
                        <v-divider />
                        <v-list-item @click="removeGuest(item.id)">
                          <v-list-item-title>
                            <v-icon small>mdi-delete-forever</v-icon>
                            {{ $t("delete-guest") }}
                          </v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-data-table>
        </v-col>
      </v-row>

      <smart-dialog title="Remarques client" v-model="dialogs.clientRemarks" :width="900"
        @close="dialogs.clientRemarks = false">
        <v-form @submit.prevent="editClientRemarks">
          <v-card-text>
            <v-textarea v-model="dialogs.remarks" />
          </v-card-text>

          <v-card-actions class="actions-btn">
            <v-spacer />
            <smart-btn primary type="submit" :loading="editButtonLoading">{{
              $t("save")
            }}
            </smart-btn>
          </v-card-actions>
        </v-form>
      </smart-dialog>

      <smart-dialog title="Ajout voyageur" v-model="editGuestDialog" :width="900" @close="loadingDetail = false">
        <v-form v-model="form.valid" ref="form" v-on:submit.prevent="valid">
          <v-card-text>
            <booking-guest-creation v-model="form.customers" :booking-status="booking.status" />
          </v-card-text>

          <v-card-actions class="actions-btn">
            <v-spacer />
            <smart-btn primary type="submit" :loading="editButtonLoading">{{
              $t("save")
            }}
            </smart-btn>
          </v-card-actions>
        </v-form>
      </smart-dialog>

      <smart-dialog title="Modifier le dossier" v-model="showEditBookingName" :width="650"
        @close="loadingDetail = false">
        <booking-context-edit v-if="showEditBookingName" :booking="booking" @exit="showEditBookingName = false" />
      </smart-dialog>

      <smart-dialog title="Message au client" v-model="email.dialog" :width="800" v-if="booking">
        <mail-composer :tos="[booking.customer.email]" :loading="email.loading" :site-id="booking.siteId"
          :default-internal-attachment="email.defaultInternalAttachment"
          :subject="email.subject || 'réf. ' + booking.id" :message="email.message" @send="sendEmail"
          showInternalAttachment />
      </smart-dialog>

      <smart-dialog title="Mise à jour du dossier" v-model="showRefreshDialog" :width="800"
        @close="loadingDetail = false">
        <v-card-text>
          <div v-if="checkdata">
            <div v-if="checkdata.success">
              Le prix des prestations n'a pas changé.
            </div>
            <div v-else>
              Les prestations suivantes ont changé:
              <ul>
                <li v-for="p in checkdata.badPrestations" :key="p.id">
                  [{{ p.type }}] {{ p.name }}:
                  <span>
                    {{ p.newPrice | currency(booking.currency) }} ({{
                      (p.newPrice - p.oldPrice)
                      | currency(booking.currency, { vary: true })
                    }})
                  </span>
                </li>
              </ul>
              Veuillez vérifier les prix.
            </div>
          </div>
          <div v-else class="text-center">
            Vérification des prix
            <v-progress-linear indeterminate class="mb-0" />
          </div>
        </v-card-text>
        <template slot="actions">
          <smart-btn secondary @click.native="showRefreshDialog = false">Fermer
          </smart-btn>

          <smart-btn primary :disabled="!checkdata" @click.native="refresh()">Mettre à jour
          </smart-btn>


        </template>
      </smart-dialog>

      <smart-dialog v-model="addActionDialog" title="Ajout action">
        <task-edit :booking-id="booking.id" @saved="addActionDialog = false"></task-edit>
      </smart-dialog>

      <smart-dialog v-model="dialogs.tagDialog.dialog" title="Etiquettes">
        <v-row class="ma-2 pa-2">
          <v-col cols="4">
            <v-subheader class>Etiquetter le dossier avec:</v-subheader>
          </v-col>
          <v-col cols="4">
            <v-select v-model="dialogs.tagDialog.selectedTags" :items="dialogs.tagDialog.tags" item-value="id"
              item-text="name" multiple></v-select>
          </v-col>
        </v-row>
        <template slot="actions">
          <smart-btn primary @click.native="tagging">Appliquer</smart-btn>
        </template>
      </smart-dialog>

      <smart-dialog v-model="dialogs.mergeGuest.show" title="Fusion de passager">
        <booking-guest-merge v-if="dialogs.mergeGuest.show" v-model="dialogs.mergeGuest.data.mergedGuest"
          :guest="dialogs.mergeGuest.data.guest" :guests="booking.globalGuests" />
        <template slot="actions">
          <smart-btn secondary @click.native="dialogs.mergeGuest.show = false">Fermer
          </smart-btn>
          <smart-btn primary :disabled="!dialogs.mergeGuest.data.mergedGuest" @click.native="mergeGuest()"
            :loading="dialogs.mergeGuest.data.merging">Fusionner
          </smart-btn>
        </template>
      </smart-dialog>

      <smart-dialog v-model="dialogs.duplicate.show" :width="320" v-on:close="dialogs.duplicate.booking = null"
        title="Dupliquer le dossier">
        <booking-duplication-dialog v-if="dialogs.duplicate.booking" :booking="dialogs.duplicate.booking"
          :show="dialogs.duplicate.show" />

      </smart-dialog>


      <smart-dialog v-model="dialogs.accountinglogsDialog" title="Ecritures comptables" width="950px" scrollable>
        <booking-accounting-logs :booking-id="booking.id" />

        <template slot="actions">
          <smart-btn secondary @click.native="dialogs.accountinglogsDialog = false">Fermer
          </smart-btn>
        </template>
      </smart-dialog>

      <smart-dialog v-model="cancelBookingDialog" title="Annuler le dossier" width="980px" scrollable
        @close="onCloseBookingCanFees(true)">
        <booking-cancel-fees :booking="booking" :covid="cancelCovid" @clicked="onCloseBookingCanFees(true)" />
      </smart-dialog>

      <smart-dialog v-model="documents.dialog" title="Ajout de documents" width="780px">
        <!-- ajout de documents -->
        <v-form xs12 ref="documentForm" @submit.prevent="saveDoc" v-model="documents.valid">
          <v-col cols="12">
            <v-row class="mt-3" justify="space-around">
              <v-col cols="3">
                <v-text-field label="Nom du passager" v-model="documents.form.lastname"
                  :rules="rules.nonEmpty('Nom obligatoire')" @input="documents.form.lastname.toUpperCase()">
                </v-text-field>
              </v-col>
              <v-col cols="3">
                <v-text-field label="Prénom du passager" v-model="documents.form.firstname"
                  :rules="rules.nonEmpty('Prénom obligaoire')" @input="documents.form.firstname.toUpperCase()">
                </v-text-field>
              </v-col>
              <v-col cols="3">
                <smart-picker label="Date de naissance" v-model="documents.form.birthdate" :inputOpts="{
                  rules: rules.nonEmpty('Date de naissance obligatoire'),
                }"></smart-picker>
              </v-col>
            </v-row>

            <v-row justify="space-around">
              <v-col cols="3">
                <v-select label="Type de document" :items="documents.documentNames" item-value="value" item-text="text"
                  v-model="documents.form.documentType" :rules="rules.nonEmpty('Type de document obligatoire')">
                </v-select>
              </v-col>
              <v-col cols="3">
                <v-text-field label="Numéro d'identification" v-model="documents.form.documentNumber" :rules="rules.nonEmpty('Numéro d\'identification obligatoire')
                  "></v-text-field>
              </v-col>
              <v-col cols="3">
                <v-select :items="countries" item-value="value" item-text="name" label="Pays d'émission"
                  v-model="documents.form.issuanceCountry" :rules="rules.nonEmpty('Pays d\'émission obligatoire')">
                </v-select>
              </v-col>
            </v-row>
            <v-row justify="space-around">
              <v-col cols="3">
                <smart-picker label="Date d'émission" v-model="documents.form.issuanceDate" :inputOpts="{
                  rules: rules.nonEmpty('Date d\'émission obligatoire'),
                }"></smart-picker>
              </v-col>
              <v-col cols="3">
                <smart-picker label="Date d'expiration du document" v-model="documents.form.documentExpirationDate"
                  :rules="rules.nonEmpty('Date d\'expiration obligatoire')"></smart-picker>
              </v-col>
              <v-col cols="3">
                <v-select :items="countries" item-value="value" item-text="name" label="Pays de résidence"
                  v-model="documents.form.residenceCountry" :rules="rules.nonEmpty('Pays de résidence obligatoire')">
                </v-select>
              </v-col>
            </v-row>
            <v-row class="pl-2" justify="start">
              <v-col class="ml-4" cols="3">
                <v-select v-if="documents.prestaPnr.length > 1" label="PNR" :items="documents.prestaPnr"
                  item-value="value" item-text="text" v-model="documents.form.pnr"
                  :rules="rules.nonEmpty('Numéro de PNR obligatoire')" no-data-text="Aucun PNR"></v-select>
                <v-select v-else label="PNR" v-model="documents.form.pnr" :items="documents.prestaPnr"
                  item-value="value" item-text="text" :value="documents.prestaPnr[0]"
                  :rules="rules.nonEmpty('Numéro de PNR obligatoire')" style="visibility: hidden"></v-select>
              </v-col>
            </v-row>
            <v-row justify="end" class="action_stripe mt-3">
              <smart-btn type="submit" :disabled="!documents.valid">Enregistrer
              </smart-btn>
            </v-row>
          </v-col>
        </v-form>
      </smart-dialog>

      <smart-dialog v-model="dialogs.book.show" title="Réserver le dossier">
        <v-row class="ma-2 pa-2">
          <v-col cols="12">
            <h4>Souhaitez réserver ce dossier ?</h4>
            <br />
            <i>Veuillez vérifier que la vérification des prix a été effectuée
            </i>
          </v-col>
          <v-col cols="12">
            <v-checkbox v-model="dialogs.book.withOption" :label="'Réserver une option ' +
              (booking.optionDateMax
                ? `(max: ${booking.optionDateMax})`
                : `(non disponible)`)
              " :disabled="booking.optionDateMax == null"></v-checkbox>
          </v-col>
        </v-row>
        <v-row v-if="dialogs.book.withOption">
          <v-col cols="8">
            <v-date-picker v-model="dialogs.book.optionDate" v-bind="datepickeroptions" :min="new Date().toISOString()"
              :max="_parseAndFormatLocalDateTime(
                booking.optionDateMax,
                datePatternConfig.en.localDateTime
              )
                " fullWidth width="300"></v-date-picker>
          </v-col>
          <v-col cols="2">
            <v-text-field label="Heure" v-model="dialogs.book.optionDateTime" v-mask="'##:##:##'" />
          </v-col>
        </v-row>
        <template slot="actions">
          <smart-btn primary @click.native="bookBooking">Réserver</smart-btn>
        </template>
      </smart-dialog>

      <smart-dialog v-model="dialogs.option.show" title="Prolonger l'option">
        <v-row class="ma-2 pa-2">
          <v-col cols="12">
            <h4>Souhaitez prolonger cette option ?</h4>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="8">
            <v-date-picker v-model="dialogs.option.optionDate" v-bind="datepickeroptions"
              :min="new Date().toISOString()" :max="_parseAndFormatLocalDateTime(
                booking.optionDateMax,
                datePatternConfig.en.localDateTime
              )
                " fullWidth width="300"></v-date-picker>
          </v-col>
          <v-col cols="2">
            <v-text-field label="Heure" v-model="dialogs.option.optionDateTime" v-mask="'##:##:##'" />
          </v-col>
        </v-row>
        <template slot="actions">
          <smart-btn primary @click.native="optionExtend()">Réserver</smart-btn>
        </template>
      </smart-dialog>

      <smart-dialog v-model="dialogs.covidInfo.dialog" title="Restriction spécifique pour l'à valoir">
        <v-row class="ma-2 pa-2">
          <v-col cols="12">
            <v-subheader class>Restriction spécifique</v-subheader>
          </v-col>
          <v-col cols="12">
            <v-textarea v-model="dialogs.covidInfo.info.value" label="Restriction"
              hint="Ce texte sera réintégrée dans l'email et le document envoyé au client" outline auto-grow>
            </v-textarea>
          </v-col>
        </v-row>
        <template slot="actions">
          <smart-btn primary @click.native="saveConvidInfo">Enregistrer
          </smart-btn>
        </template>
      </smart-dialog>

      <smart-dialog title="Informations navigateur client" v-model="dialogs.customerBrowserInfo" :width="400">
        <v-col class="pt-2">
          <v-row>
            <v-col cols="12" v-if="userAgent.ua">
              <v-list xs12 subheader two-line dense>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-sub-title>IP</v-list-item-sub-title>
                    <v-list-item-title class="ipLocatorLink" @click="openIpLocator(booking.infos.ipAddress)">
                      {{ booking.infos.ipAddress }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <v-divider></v-divider>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-sub-title>Browser</v-list-item-sub-title>
                    <v-list-item-title>{{ userAgent.browser.name }}
                      {{ userAgent.browser.major }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-sub-title>Engine</v-list-item-sub-title>
                    <v-list-item-title>{{ userAgent.engine.name }}
                      {{ userAgent.engine.version }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-sub-title>Os</v-list-item-sub-title>
                    <v-list-item-title>{{ userAgent.os.name }}
                      {{ userAgent.os.version }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-divider v-if="
                  userAgent.device.vendor ||
                  userAgent.device.model ||
                  userAgent.device.type
                ">
                </v-divider>
                <v-list-item v-if="
                  userAgent.device.vendor ||
                  userAgent.device.model ||
                  userAgent.device.type
                ">
                  <v-list-item-content>
                    <v-list-item-sub-title v-if="
                      userAgent.device.vendor ||
                      userAgent.device.model ||
                      userAgent.device.type
                    ">Device
                    </v-list-item-sub-title>
                    <v-list-item-title>{{ userAgent.device.vendor }}
                      {{ userAgent.device.model }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
            <v-col class="py-5" cols="12" v-else>
              <v-row justify="space-around">
                <v-subheader>Aucune Information</v-subheader>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </smart-dialog>
      <smart-dialog v-model="reportdialog" title="Report du dossier" :width="900">
        <booking-postpone :booking="booking" />
      </smart-dialog>
      <smart-dialog :title="booking.blockPriceDate ? 'Modifier le blocage des prix' : 'Blocage des prix'"
        v-model="dialogs.blockPrice.show" :width="500" @close="loadingDetail = false">
        <v-form v-model="dialogs.blockPrice.form.valid" ref="blockPrice" v-on:submit.prevent="blockPrices">
          <div class="pa-3">
            <smart-picker class="mx-2 mt-2" label="Date d'expiration" v-model="dialogs.blockPrice.form.date" :inputOpts="{
              rules: rules.nonEmpty('Date de blocage obligatoire'),
            }">
            </smart-picker>
            <div class="d-flex mt-4">
              <v-text-field dense type="number" class="mr-3" v-model="dialogs.blockPrice.form.hours" label="Heure" />
              <v-text-field dense type="number" v-model="dialogs.blockPrice.form.minutes" label="Minute" />
            </div>
          </div>

          <v-card-actions class="actions-btn">
            <v-spacer />
            <smart-btn primary type="submit" :loading="editButtonLoading">{{
              $t("save")
            }}
            </smart-btn>
          </v-card-actions>
        </v-form>
      </smart-dialog>

    </template>

    <div slot="menu">
      <template v-if="booking.optionDate != null">
        <v-list-item @click="optionConfirm()">
          <v-list-item-title>
            <v-icon small>mdi-check-underline</v-icon>
            Confirmer l'option
          </v-list-item-title>
        </v-list-item>
        <v-list-item @click="showOptionExtend()">
          <v-list-item-title>
            <v-icon small>mdi-history</v-icon>
            Prolonger l'option
          </v-list-item-title>
        </v-list-item>
        <v-divider />
      </template>

      <v-list-item :disabled="!canBook" @click="dialogs.book.show = true">
        <v-list-item-title>
          <v-icon small>mdi-check-underline</v-icon>
          Réserver le dossier
        </v-list-item-title>
      </v-list-item>

      <v-list-item :disabled="!canCancel" @click="cancelBooking()">
        <v-list-item-title>
          <v-icon small>mdi-cancel</v-icon>
          Annuler le dossier
        </v-list-item-title>
      </v-list-item>
      <!--
      <v-list-item :disabled="!canCancel" @click="cancelBookingCovid()">
        <v-list-item-title>
          <v-icon small>mdi-cancel</v-icon>Annuler le dossier Covid19
        </v-list-item-title>
      </v-list-item>
      -->
      <v-list-item @click="lockBooking">
        <v-list-item-title>
          <v-icon small>mdi-lock</v-icon>
          Verrouiller le dossier
        </v-list-item-title>
      </v-list-item>
      <v-divider />
      <v-list-item @click="create" v-if="guests.length == 0">
        <v-list-item-title>
          <v-icon small>mdi-account-plus-outline</v-icon>
          {{ $t("add-guest") }}
        </v-list-item-title>
      </v-list-item>
      <v-list-item @click="edit" v-else>
        <v-list-item-title>
          <v-icon small>mdi-account-edit</v-icon>
          {{ $t("edit-guests") }}
        </v-list-item-title>
      </v-list-item>
      <v-list-item @click="
        loadingDetail = true;
      showEditBookingName = true;
      ">
        <v-list-item-title>
          <v-icon small>mdi-briefcase-edit-outline</v-icon>
          Modifier le dossier
        </v-list-item-title>
      </v-list-item>
      <v-list-item @click="setQuotation" :disabled="booking.status != 'cart'">
        <v-list-item-title>
          <v-icon small>mdi-clipboard-check</v-icon>
          Passer en devis
        </v-list-item-title>
      </v-list-item>
      <v-divider />
      <v-list-item @click="
        loadingDetail = true;
      check();
      ">
        <v-list-item-title>
          <v-icon small>mdi-reload</v-icon>
          Vérifier les prix
        </v-list-item-title>
      </v-list-item>

      <v-list-item @click="dialogs.blockPrice.show = true" :disabled="blockedPrice || booking.status != 'quotation'">
        <v-list-item-title>
          <v-icon small>mdi-lock</v-icon>
          Bloquer les prix
        </v-list-item-title>
      </v-list-item>


      <v-list-item @click="loadingDetail = true;
      duplicate();">
        <v-list-item-title>
          <v-icon small>mdi-content-duplicate</v-icon>
          Dupliquer le dossier
        </v-list-item-title>
      </v-list-item>
      <v-list-item @click="reportdialog = true">
        <v-list-item-title>
          <v-icon small>mdi-calendar-arrow-right</v-icon>
          Reporter le dossier
        </v-list-item-title>
      </v-list-item>
      <v-divider />
      <v-list-item :disabled="listingLead.leads.length == 0" @click="goToLead()">
        <v-list-item-title>
          <v-icon small>mdi-layers</v-icon>
          Voir {{ listingLead.leads.length > 1 ? `les leads (${listingLead.leads.length})` : "le lead" }}
        </v-list-item-title>
      </v-list-item>
      <v-divider />
      <v-list-item @click="addActionDialog = true">
        <v-list-item-title>
          <v-icon small>mdi-playlist-plus</v-icon>
          Ajouter une action
        </v-list-item-title>
      </v-list-item>
      <v-list-item @click="dialogs.tagDialog.dialog = true">
        <v-list-item-title>
          <v-icon small>mdi-tag-plus</v-icon>
          Ajouter une étiquette
        </v-list-item-title>
      </v-list-item>
      <v-divider />
      <v-list-item @click="openMailComposer()">
        <v-list-item-title>
          <v-icon small>mdi-email-outline</v-icon>
          {{ $t("message-customer") }}
        </v-list-item-title>
      </v-list-item>
      <v-list-item @click="openMailComposer('quotation')">
        <v-list-item-title>
          <v-icon small>mdi-email-outline</v-icon>
          Message devis
        </v-list-item-title>
      </v-list-item>
      <v-list-item @click="openMailComposer('bulletin')">
        <v-list-item-title>
          <v-icon small>mdi-email-outline</v-icon>
          Message contrat de vente
        </v-list-item-title>
      </v-list-item>
      <v-list-item @click="openMailComposer('vouchers')">
        <v-list-item-title>
          <v-icon small>mdi-email-outline</v-icon>
          Message vouchers
        </v-list-item-title>
      </v-list-item>
      <v-list-item @click="regenMails('customer')">
        <v-list-item-title>
          <v-icon small>mdi-reload</v-icon>
          Ré-envoyer confirmation client
        </v-list-item-title>
      </v-list-item>
      <v-list-item @click="regenMails('supplier')">
        <v-list-item-title>
          <v-icon small>mdi-reload</v-icon>
          Ré-envoyer confirmation fournisseurs
        </v-list-item-title>
      </v-list-item>
      <v-divider />
      <v-list-item @click="openLocksMails()">
        <v-list-item-title>
          <v-icon small>mdi-email-outline</v-icon>
          Messages en attente
        </v-list-item-title>
      </v-list-item>
      <v-list-item @click="dialogs.covidInfo.dialog = true">
        <v-list-item-title>
          <v-icon small>mdi-format-text</v-icon>
          Restriction à valoir
        </v-list-item-title>
      </v-list-item>
      <v-divider />
      <v-list-item @click="downloadQuotation">
        <v-list-item-title>
          <v-icon small>mdi-download</v-icon>
          Télécharger le devis
        </v-list-item-title>
      </v-list-item>
      <v-list-item @click="downloadContratVente">
        <v-list-item-title>
          <v-icon small>mdi-download</v-icon>
          Télécharger le contrat de vente
        </v-list-item-title>
      </v-list-item>
      <!--
        fyv: on est d’accord qu’il n’y a AUCUN intérêt à éditer une proforma d’un dossier reservé ?
        Dom: je dirai même plus c’est illégal
        le proforma n’est valable que pour un dossier en devis
       -->
      <v-list-item :disabled="booking.status == 'booked'" @click="downloadProforma">
        <v-list-item-title>
          <v-icon small>mdi-download</v-icon>
          Télécharger Facture Proforma
        </v-list-item-title>
      </v-list-item>
      <v-list-item @click="downloadAllVoucher">
        <v-list-item-title>
          <v-icon small>mdi-download-multiple</v-icon>
          Télécharger tous les
          vouchers
        </v-list-item-title>
      </v-list-item>
      <v-divider />
      <v-list-item @click="accountinglogs">
        <v-list-item-title>
          <v-icon small>mdi-scale-balance</v-icon>
          Voir les écritures comptable
        </v-list-item-title>
      </v-list-item>
    </div>
  </collapse-panel>
</template>

<script>
import CollapsePanel from "@/components/commons/CollapsePanel";
import Availability from "@/components/commons/Availability";
import { get, post } from "@/utils/api";
import { EventBus } from "@/components/commons/event-bus";
import CustomerAddress from "@/components/commons/CustomerAddress";
import CustomerInfos from "@/components/commons/CustomerInfos";
import FormMixin from "@/components/mixins/FormMixin";
import GuestsMixin from "../mixins/GuestsMixin";
import BookingGuestCreation from "./BookingGuestCreation";
import MailComposer from "../commons/MailComposer";
import TaskEdit from "@/components/commons/TaskEdit";
import BookingGuestMerge from "@/components/booking/BookingGuestMerge";
import BookingAccountingLogs from "@/components/booking/BookingAccountingLogs";
import BookingCancelFees from "@/components/commons/BookingCancelFees";
import BookingContextEdit from "./BookingContextEdit";
import * as UAParser from "ua-parser-js";
import _ from "lodash";
import BookingPostpone from "./BookingPostpone";
import LeadsListTable from "@/views/lead/leads-list-table.vue";
import BookingDuplicationDialog from "@/components/booking/BookingDuplicationDialog.vue";

export default {
  name: "BookingDetails",
  components: {
    BookingDuplicationDialog,
    LeadsListTable,
    BookingPostpone,
    BookingContextEdit,
    TaskEdit,
    MailComposer,
    BookingGuestCreation,
    CustomerInfos,
    CustomerAddress,
    Availability,
    CollapsePanel,
    BookingGuestMerge,
    BookingAccountingLogs,
    BookingCancelFees,
  },
  mixins: [GuestsMixin, FormMixin],
  props: {
    booking: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      listingLead: {
        show: false,
        leads: []
      },
      datepickeroptions: {
        "first-day-of-week": 1,
        locale: "fr-fr",
        "no-title": true,
        "event-color": "blue lighten-2",
        "picker-date": null,
      },
      reportdialog: false,
      site: null,
      loadingDetail: false,
      guests: [],
      guestsOpen: false,
      editGuestDialog: false,
      addActionDialog: false,
      guestIdToDelete: null,
      cancelBookingDialog: null,
      cancelCovid: false,
      countries: null,
      form: {
        valid: true,
        customers: [],
      },

      pagination: {
        rowsPerPageItems: [{ text: "Tous", value: -1 }, 50, 200],
        page: 1,
        sortBy: [],
        sortDesc: [false],
      },
      isEdition: null,
      deleteLoading: false,
      editButtonLoading: false,
      showEditBookingName: false,
      showRefreshDialog: false,

      documents: {
        dialog: false,
        currentGuest: null,
        prestaPnr: [],
        documentNames: [
          {
            text: "Passeport",
            value: "PASSPORT",
          },
          {
            text: "Carte d'identité",
            value: "ID_CARD",
          },
        ],
        valid: true,
        form: {
          pnr: null,
          passengerId: null,
          firstname: null,
          lastname: null,
          birthdate: null,
          documentType: null,
          documentNumber: null,
          documentExpirationDate: null,
          issuanceDate: null,
          issuanceCountry: "FR",
          residenceCountry: "FR",
          env: {
            siteId: null,
            priceClassTypeId: null,
          },
          ageClassType: null,
        },
      },

      email: {
        loading: false,
        dialog: false,
        defaultInternalAttachment: null,
        subject: null,
        message: null,
      },

      canCancelStatus: ["booked", "option"],
      canBookStatus: ["quotation", "option"],
      userAgent: null,
      checkdata: null,
      dialogs: {
        blockPrice: {
          show: false,
          form: {
            valid: true,
            date: null,
            hours: 0,
            minutes: 0,

          }
        },
        book: {
          withOption: false,
          show: false,
          optionDate: null,
          optionDateTime: "16:00:00",
        },
        option: {
          show: false,
          optionDate: null,
          optionDateTime: "16:00:00",
        },
        remarks: "",
        clientRemarks: false,
        mergeGuest: {
          show: false,
          data: {
            guest: null,
            mergedGuest: null,
            merging: false,
          },
        },
        duplicate: {
          show: false,
          id: null,
          booking: null
        },
        tagDialog: {
          dialog: false,
          tags: [],
          selectedTags: [],
        },
        covidInfo: {
          dialog: false,
          info: (() => {
            const obj = this.booking.additionalInfos.filter(
              (i) => i.item == "covidrestriction"
            )[0];

            return obj
              ? { ...obj, ...{ value: JSON.parse(obj.value) } }
              : undefined;
          })() || {
            id: null,
            item: "covidrestriction",
            value: null,
          },
        },
        accountinglogsDialog: false,
        cancelForm: [],

        flightPriceClassTypes: [],
        currentPassenger: null,
        customerBrowserInfo: false,
      },
    };
  },
  created() {
    this.init();

    this.booking.prestations.map((prestation, index) => {
      if (prestation.segments) {
        prestation.segments.map((segment, idx) => {
          this.getCancelFees(segment, index, idx);
        });
      } else {
        this.getCancelFees(prestation, index);
      }
    });

    const formDateTime = this.booking.paymentOrders.length > 0 ? this.booking.paymentOrders[0].expirationDate : null

    let limitationBlockDate = formDateTime ? formDateTime : this._formatDate(this._addDays(new Date(), 1).setHours(new Date().getHours(), new Date().getMinutes()), "dd/MM/yyyy hh:mm")

    this.dialogs.blockPrice.form.date = this.booking.blockPriceDate && this.booking.paymentOrders.length == 0 ? this.booking.blockPriceDate.substring(0, 10) : limitationBlockDate.split(" ")[0]
    this.dialogs.blockPrice.form.hours = this.booking.blockPriceDate && this.booking.paymentOrders.length == 0 ? parseInt(this.booking.blockPriceDate.substring(11, 13)) : parseInt(limitationBlockDate.substring(11, 13))
    this.dialogs.blockPrice.form.minutes = this.booking.blockPriceDate && this.booking.paymentOrders.length == 0 ? parseInt(this.booking.blockPriceDate.substring(14, 16)) : parseInt(limitationBlockDate.substring(14, 16))

    get("/api/search/countries?q=").json((countries) => {
      this.countries = countries.map((c) => {
        return {
          name: c.name,
          value: c.code,
        };
      });
    });

    get(`/api/tags/${this.booking.id}`).json((tags) => {
      this.dialogs.tagDialog.selectedTags = tags;
    });
    get("/api/search/tags").json((tags) => {
      this.dialogs.tagDialog.tags = tags;
    });
    get("/api/search/priceclasstypes").json((priceClassTypes) => {
      this.dialogs.flightPriceClassTypes = priceClassTypes.reduce(
        (acc, curr) => {
          acc = curr.withFlight ? [...[curr], ...acc] : acc;
          return acc;
        },
        []
      );
    });
    get(`/api/lead/list/${this.booking.id}`).json((leads) => {
      this.listingLead.leads = leads
    })
  },
  watch: {
    booking(newVal, oldVal) {
      this.init();
    },
  },

  computed: {
    hasFlight() {
      return this.booking.prestations.find(
        (p) => p.kind === "FlightPrestation"
      );
    },
    isBadPriceClass() {
      return (
        this.booking.prestations.length > 0 &&
        ((this.hasFlight &&
          this.dialogs.flightPriceClassTypes.find(
            (p) => p.id === this.booking.context.priceClassType.id
          ) == null) ||
          (!this.hasFlight &&
            this.dialogs.flightPriceClassTypes.find(
              (p) => p.id === this.booking.context.priceClassType.id
            )))
      );
    },
    blockedPrice() {
      return this.booking?.blockPriceDate != null && this._isBefore(new Date(), this._parseDate(this.booking.blockPriceDate, "dd/MM/yyyy hh:mm:ss"))
    },

    canBook() {
      return this.canBookStatus.includes(this.booking.status);
    },
    canCancel() {
      return this.canCancelStatus.includes(this.booking.status);
    },
    tags() {
      return [
        {
          code: "source",
          name: "Source",
          value: this.$t(this.booking.context.source),
        },
        { code: "site", name: "Site", value: this.booking.context.site.name },
        //{name:'Langue', value:this.booking.context.lang.name},
        {
          code: "priceClassType",
          name: "Classe de prix de vente",
          value: this.booking.context.priceClassType.name,
        },
        {
          code: "currency",
          name: "Vente",
          value: this.booking.context.currency.code,
        },
        {
          code: "country",
          name: "Pays du client",
          value: this.booking.context.countryCode,
        },
        {
          code: "lang",
          name: "Langue du client",
          value: this.booking.context.lang.name,
        },
        ...this.booking.context.declarations.map((d) => {
          return { code: "declaration", name: "Déclaration", value: d };
        }),
      ];
    },
    remarks() {
      return this.booking.additionalInfos
        .filter((a) => a.item === "clientremarks")
        .map((a) => JSON.parse(a.value))
        .join(", ");
    },
    cancelFormTotal: {
      get: function () {
        return this.totalCancelFee().price;
      },
      set: function (newVal) {
        this.cancelFormTotal = newVal;
      },
    },
  },
  methods: {
    init() {
      this.checkPriceInit();

      //travelers
      this.guests = _.sortBy(this.booking.globalGuests, "id");

      let parser = new UAParser();
      this.userAgent = parser.setUA(this.booking.infos.userAgent).getResult();

      if (this.booking.siteId === 1) {
        this.site = { name: "Hotelissima", color: "#01779D" };
      } else if (this.bookingId === 2) {
        this.site = { name: "B2B", color: "grey" };
      } else if (this.bookingId === 3) {
        this.site = { name: "Hotels & Lagons", color: "#b0a88f" };
      } else if (this.bookingId === 4) {
        this.site = { name: "Zil Maurice", color: "#F5CF7A" };
      } else if (this.bookingId === 5) {
        this.site = { name: "Zil Caraïbes", color: "#31313A" };
      } else {
        this.site = { name: "???", color: "pink" };
      }

      this.booking.prestations.reduce((acc, curr) => {
        if (
          curr.pnr &&
          curr.status == "booked" &&
          this.documents.prestaPnr.find((pnr) => pnr.value == curr.pnr) ==
          undefined
        ) {
          this.documents.prestaPnr.push({ text: curr.pnr, value: curr.pnr });
        }
        return acc;
      }, []);
    },
    editClientRemarks() {
      const infos = this.booking.additionalInfos.find(
        (a) => a.item === "clientremarks"
      );
      const id = infos ? infos.id : null;

      post(`/api/booking/${this.booking.id}/additionalinfos`, {
        id: id,
        item: "clientremarks",
        value: this.dialogs.remarks,
      })
        .res(() => {
          return;
        })
        .then(() => {
          EventBus.$emit("reloadbooking", {});
          this.dialogs.clientRemarks = false;
        });
    },
    optionConfirm() {
      return post(`/api/booking/${this.booking.id}/option/confirm`)
        .badRequest((err) => {
          EventBus.$emit(
            "toaster-msg",
            JSON.parse(err.null).errors.map((e) => JSON.parse(e))
          );
        })
        .res(() => {
          return;
        })
        .then(() => {
          EventBus.$emit("reloadbooking", {});
          EventBus.$emit("toaster-msg", "Option confirmée");
          this.loadingDetail = false;
          this.dialogs.book.show = false;
        });
    },
    showOptionExtend() {
      this.dialogs.option.optionDate = this._parseAndFormatDate(
        this.booking.optionDate,
        this.datePatternConfig.serverLocalDateTime,
        "yyyy-MM-dd"
      );
      this.dialogs.option.optionDateTime = this._parseAndFormatDate(
        this.booking.optionDate,
        this.datePatternConfig.serverLocalDateTime,
        "HH:mm:ss"
      );
      this.dialogs.option.show = true;
    },
    optionExtend() {
      const qs =
        this.dialogs.option.optionDate != null
          ? `optionDate=${this.dialogs.option.optionDate}T${this.dialogs.option.optionDateTime}`
          : "";
      return post(`/api/booking/${this.booking.id}/option/extend?${qs}`)
        .badRequest((err) => {
          EventBus.$emit(
            "toaster-msg",
            JSON.parse(err.null).errors.map((e) => JSON.parse(e))
          );
        })
        .res(() => {
          return;
        })
        .then(() => {
          EventBus.$emit("reloadbooking", {});
          EventBus.$emit("toaster-msg", "Option prolongée");
          this.loadingDetail = false;
          this.dialogs.option.show = false;
        });
    },
    bookBooking() {
      this.loadingDetail = true;

      const qs =
        this.dialogs.book.optionDate != null
          ? `optionDate=${this.dialogs.book.optionDate}T${this.dialogs.book.optionDateTime}`
          : "";

      return get(`/api/booking/book/${this.booking.id}?${qs}`)
        .badRequest((err) => {
          EventBus.$emit(
            "toaster-msg",
            JSON.parse(err.null).errors.map((e) => JSON.parse(e))
          );
        })
        .res(() => {
          return;
        })
        .then(() => {
          EventBus.$emit("reloadbooking", {});
          EventBus.$emit("toaster-msg", "Confirmation enregistrée");
          this.loadingDetail = false;
          this.dialogs.book.show = false;
        });
    },
    cancelBooking(cause) {
      this.loadingDetail = true;
      this.cancelBookingDialog = true;
      this.cancelCovid = cause === "covid";
    },
    cancelBookingCovid() {
      this.$root
        .$confirm(
          this.$t("confirm"),
          "Etes-vous sur de vouloir annuler le dossier (COVID-19)",
          { width: 450 }
        )
        .then((confirm) => {
          if (confirm) {
            const url = `/api/booking/cancel/${this.booking.id}/covid`;
            return post(url)
              .badRequest((err) => {
                this.isLoading = false;
                EventBus.$emit("toaster-error", err.message);
              })
              .res((res) => {
                this.isLoading = false;
                EventBus.$emit("reloadbooking", {});
                this.$emit("clicked", true);
              });
          }
        });
    },
    lockBooking() {
      this.loadingDetail = true;

      this.$root
        .$confirm(
          this.$t("confirm"),
          "Souhaitez vous vérouiller ce dossier ? ",
          { width: 450 }
        )
        .then((confirm) => {
          if (!confirm) {
            this.loadingDetail = false;
            return Promise.resolve();
          } else {
            return get(`/api/booking/${this.booking.id}/lock`)
              .badRequest((err) => {
              })
              .res(() => {
                return;
              })
              .then(() => {
                EventBus.$emit("reloadbooking", {});
                EventBus.$emit("toaster-msg", "Dossier vérouillé");
                this.loadingDetail = false;
              });
          }
        });
    },
    sendEmail(email) {
      this.email.loading = true;
      const url = `/api/mail/sendCustomer/${this.booking.id}`;
      post(url, email)
        .json((r) => {
          EventBus.$emit("toaster-msg", "Email envoyé");
          EventBus.$emit("reloadbooking", {});
          return r;
        })
        .then(() => {
          this.email.loading = false;
          this.email.dialog = false;
        });
    },
    destinationResume(prestationsRooms) {
      return _.uniq(
        prestationsRooms.map((p) => {
          if (p !== undefined && p.destination) {
            return p.destination.name;
          }
        })
      ).join(" / ");
    },
    downloadQuotation() {
      const url = `/document/quotation/${this.booking.id}?inline=true`;
      const win = window.open(url, "_blank");
      win.focus();
    },
    downloadContratVente() {
      const url = `/document/bulletin/${this.booking.id}`;
      const win = window.open(url, "_blank");
      win.focus();
    },
    downloadAllVoucher() {
      const url = `/document/allvoucher/${this.booking.id}`;
      const win = window.open(url, "_blank");
      win.focus();
    },
    downloadProforma() {
      const url = `/document/invoice/${this.booking.id}?proforma=true`;
      const win = window.open(url, "_blank");
      win.focus();
    },
    //travelers methods
    stateDefault() {
      this.loadingDetail = false;
      this.deleteLoading = false;
      this.form.valid = true;
      this.editGuestDialog = true;
    },
    showMergeGuest(guestId) {
      this.dialogs.mergeGuest.show = true;
      this.dialogs.mergeGuest.data.guest = this.booking.globalGuests.find(
        (g) => g.id === guestId
      );
    },
    mergeGuest() {
      this.dialogs.mergeGuest.data.merging = true;
      post(
        `/api/guest/${this.dialogs.mergeGuest.data.guest.id}/merge`,
        this.dialogs.mergeGuest.data.mergedGuest
      ).json((data) => {
        if (data.changed) {
          this.check();
        }

        this.dialogs.mergeGuest.show = false;
        this.dialogs.mergeGuest.data.merging = false;
        EventBus.$emit("reloadbooking", {});
      });
    },
    removeGuest(guestId) {
      this.deleteLoading = true;
      this.$root
        .$confirm(this.$t("delete-guest"), this.$t("delete-guest-validation"), {
          width: 350,
        })
        .then((confirm) => {
          if (!confirm) {
            return Promise.resolve();
          } else {
            get(`/api/guest/delete/${guestId}/${this.booking.id}`)
              .badRequest((err) => {
                EventBus.$emit(
                  "toaster-msg",
                  JSON.parse(err.null).errors.map((e) => JSON.parse(e))
                );
              })
              .json((data) => {
                if (data.errors.length > 0) {
                  EventBus.$emit("toast", {
                    color: "error",
                    text: "Erreur: " + data.errors.join("\n"),
                  });
                } else {
                  EventBus.$emit("toast", {
                    color: "success",
                    text: "Le voyageur a été bien été supprimé",
                  });
                }

                EventBus.$emit("reloadbooking", {});
              });
          }
        })
        .then(() => (this.deleteLoading = false));
    },
    create() {
      this.stateDefault();
      this.isEdition = false;
      this.loadingDetail = true;
      this.form = {
        valid: true,
        customers: [],
      };
    },
    edit() {
      this.stateDefault();
      this.isEdition = true;
      this.loadingDetail = true;

      this.form = {
        valid: true,
        customers: _.cloneDeep(this.guests),
      };
    },
    addCustomer() {
      this.stateDefault();
      this.form.customers.push({ ...this.defaultCustomer });
    },
    valid() {
      if (this.$refs.form.validate()) {
        this.editButtonLoading = true;
        this.loadingDetail = true;

        const form = this.form.customers.map((form) => {
          return {
            ...form,
            ...{
              birthDate:
                form.birthDate && form.birthDate.trim() !== ""
                  ? form.birthDate
                  : null,
            },
          };
        });

        const url = `/api/guest/save/${this.booking.id}`;
        post(url, form)
          .badRequest((err) => {
            EventBus.$emit(
              "toaster-msg",
              JSON.parse(err.null).errors.map((e) => JSON.parse(e))
            );
          })
          .json(() => {
            this.editGuestDialog = !this.editGuestDialog;
          })
          .then(() => {
            EventBus.$emit("toaster-msg", "Voyageur mis à jour");
            EventBus.$emit("reloadbooking", {});
            EventBus.$emit("reloadpassangers", {});
            this.loadingDetail = false;
            this.editButtonLoading = false;
          });
      }
    },
    cancel() {
      this.$refs.form.reset();
      this.editGuestDialog = false;
      this.loadingDetail = false;
    },
    check() {
      this.showRefreshDialog = true;
      this.checkdata = null;
      this.checkPriceWs().then((data) => {
        this.checkdata = data;
      });
    },
    setQuotation() {
      this.$root
        .$confirm(
          this.$t("confirm"),
          "Transformer ce dossier en devis ? <br/> Ce dossier apparaitra dans la liste des devis de l'agence",
          { width: 350, isHtml: true }
        )
        .then((confirm) => {
          if (!confirm) {
            return Promise.resolve();
          } else {
            const url = `/ws/booking/${this.booking.id}/quote`;

            post(url, JSON.stringify("'user'"), {
              "Content-Type": "application/json"
            })
              .json((r) => {
                return r;
              })
              .then(() => {
                EventBus.$emit("toaster-msg", "Dossier mis à jour");
                EventBus.$emit("reloadbooking", {});
              });
          }
        });
    },
    checkPriceInit() {
      const toTests = ["quotation", "cart"];

      const now = new Date();
      const startDate = this._parseDate(
        this.booking.startDate,
        "dd/MM/yyyy HH:mm:ss"
      );

      if (
        this._isAfter(startDate, now) &&
        toTests.indexOf(this.booking.status) >= 0
      ) {
        this.checkPriceWs().then((data) => {
          if (data.badPrestations.length > 0) {
            this.$emit("priceWarn", data.badPrestations);
          }
        });
      }
    },
    checkPriceWs() {
      return get(
        `/api/booking/${this.booking.id}/check-price?withFlightDeltaPrice=false`
      )
        .badRequest((err) => {
          EventBus.$emit("toaster-msg", err);
        })
        .json((data) => {
          return data;
        });
    },
    duplicate() {
      this.dialogs.duplicate = {
        booking: this.booking,
        show: true,
        id: this.booking.id
      }
      this.loadingDetail = false;
    },
    openDuplicate() {
      let route = this.$router.resolve({
        name: "Booking",
        params: { id: this.dialogs.duplicate.id },
      });
      window.open(route.href, "_blank");
    },
    refresh() {
      get(`/api/booking/${this.booking.id}/refresh-price`)
        .badRequest((err) => {
          EventBus.$emit("toaster-msg", err);
        })
        .res(() => {
          EventBus.$emit("toaster-msg", "Dossier mis à jour");
          EventBus.$emit("reloadbooking", {});
          this.showRefreshDialog = false;
        });
    },
    openMailComposer(defaultInternalAttachment) {
      this.buildContentsForEmail(defaultInternalAttachment).then(
        ({ subject, message }) => {
          this.email.defaultInternalAttachment = defaultInternalAttachment
            ? [defaultInternalAttachment]
            : null;
          this.email.subject = subject + " réf. " + this.booking.id;
          this.email.message = message;
          this.email.dialog = true;
        }
      );
    },
    buildContentsForEmail(attachment) {
      if (attachment) {
        return get(
          `/api/mail/contentForFront/${this.booking.id}?emailType=${attachment}`
        ).json((r) => {
          return r;
        });
      } else {
        return new Promise((res, rej) => {
          res({ subject: "", message: "" });
        });
      }
    },
    openLocksMails() {
      EventBus.$emit("open-lock-mails");
    },
    accountinglogs() {
      this.dialogs.accountinglogsDialog = true;
    },
    getCancelFees(prestation, index, idx) {
      let fee = null;
      const now = new Date();
      fee = prestation.cancelFees.fees.find((f) => {
        return (
          this._parseDate(f.start, "dd/MM/yyyy") < now &&
          this._parseDate(f.stop, "dd/MM/yyyy") > now
        );
      });

      if (
        !fee &&
        prestation.cancelFees.fees &&
        prestation.cancelFees.fees.length > 0
      ) {
        if (
          this._parseDate(prestation.cancelFees.fees[0].start, "dd/MM/yyyy") >
          now
        ) {
          fee = prestation.cancelFees.fees[0];
        } else if (
          prestation.cancelFees.fees &&
          this._parseDate(
            prestation.cancelFees.fees[prestation.cancelFees.fees.length - 1]
              .stop,
            "dd/MM/yyyy"
          ) < now
        ) {
          fee =
            prestation.cancelFees.fees[prestation.cancelFees.fees.length - 1];
        }
      }

      //return fee ? fee : null
      if (idx == undefined) {
        this.dialogs.cancelForm[index] = fee ? fee.price : 0;
      } else {
        this.dialogs.cancelForm[index] = [];
        this.dialogs.cancelForm[index][idx] = fee ? fee.price : 0;
      }
    },
    totalCancelFee() {
      const now = new Date();
      let fee = this.booking.cancelfees.fees.find((f) => {
        return (
          this._parseDate(f.start, "dd/MM/yyyy") < now &&
          this._parseDate(f.stop, "dd/MM/yyyy") > now
        );
      });

      if (!fee && this.booking.cancelfees.fees.length > 0) {
        if (
          this._parseDate(this.booking.cancelfees.fees[0].start, "dd/MM/yyyy") >
          now
        ) {
          fee = this.booking.cancelfees.fees[0];
        } else {
          fee =
            this.booking.cancelfees.fees[
            this.booking.cancelfees.fees.length - 1
            ];
        }
      }
      return fee;
    },
    onCloseBookingCanFees(val) {
      if (val) {
        this.loadingDetail = false;
        this.cancelBookingDialog = false;
      }
    },
    browserInfo() {
      this.dialogs.customerBrowserInfo = true;
    },
    openIpLocator(ip) {
      const url = `https://api.ipgeolocation.io/ipgeo?apiKey=ca00710c4a6e49429ea42f37a0a65fdd&ip=${ip}`;

      window.open(url, "_blank");
    },
    openDocumentDialog(passenger) {
      this.documents.dialog = true;

      this.booking.prestations.reduce((acc, curr) => {
        if (
          curr.pnr &&
          curr.status == "booked" &&
          this.documents.prestaPnr.find((pnr) => pnr.value == curr.pnr) ==
          undefined
        ) {
          this.documents.prestaPnr.push({ text: curr.pnr, value: curr.pnr });
        }
        return acc;
      }, []);

      this.documents.currentGuest = passenger;
      this.documents.form.firstname = passenger.firstName.toUpperCase();
      this.documents.form.lastname = passenger.lastName.toUpperCase();
      this.documents.form.birthdate = passenger.birthDate;

      let pnrList = [];

      this.booking.prestations.map((presta) => {
        if (
          presta.kind == "FlightPrestation" &&
          !presta.isLast &&
          presta.status == "booked"
        ) {
          pnrList.push(presta);
        }
      });

      this.documents.form.pnr = pnrList.length == 1 ? pnrList[0].pnr : null;
      this.documents.form.documentExpirationDate =
        pnrList.length == 1
          ? this._parseAndFormatDate(
            pnrList[0].expirationDate,
            this.datePatternConfig.serverLocalDateTime,
            this.datePatternConfig.serverLocalDate
          )
          : null;
      this.documents.form.issuanceDate =
        pnrList.length == 1 && pnrList[0].emittedDate
          ? this._parseAndFormatDate(
            pnrList[0].emittedDate,
            this.datePatternConfig.serverLocalDateTime,
            this.datePatternConfig.serverLocalDate
          )
          : null;
      this.documents.form.env = {
        siteId: this.booking.context.site.id,
        priceClassTypeId: this.booking.context.priceClassType.id,
      };
      this.documents.form.ageClassType = passenger.ageClassType;
    },
    saveDoc() {
      if (this.$refs.documentForm.validate()) {
        const url = `/api/guest/${this.documents.currentGuest.id}/documents/add`;

        this.documents.form.passengerId =
          this.documents.currentGuest.id.toString();

        post(url, this.documents.form)
          .badRequest((err) => {
            EventBus.$emit(
              "toaster-msg",
              JSON.parse(err.null).errors.map((e) => JSON.parse(e))
            );
          })
          .json((r) => {
            let j = JSON.parse(r);

            if (j.status == "Success") {
              EventBus.$emit("toaster-msg", "Document ajouté");
            } else {
              EventBus.$emit(
                "toaster-error",
                "Erreur lors de l'ajout du document"
              );
            }
            this.documents.dialog = false;
          });
      }
    },

    tagging() {
      const obj = {
        bookingIds: [this.booking.id],
        tagIds: this.dialogs.tagDialog.selectedTags,
      };

      post("/api/tags/tagging", obj).res((r) => {
        EventBus.$emit("toaster-msg", "Etiquettes appliquées");
        EventBus.$emit("reloadbooking", {});
        this.dialogs.tagDialog.dialog = false;
      });
    },
    saveConvidInfo() {
      const url = this.dialogs.covidInfo.info.id
        ? `/api/booking/${this.booking.id}/editadditionalinfos/${this.dialogs.covidInfo.info.id}`
        : `/api/booking/${this.booking.id}/addadditionalinfos`;
      const obj = {
        ...this.dialogs.covidInfo.info,
        ...{ value: JSON.stringify(this.dialogs.covidInfo.info.value) },
      };

      post(url, obj).res((r) => {
        EventBus.$emit("toaster-msg", "Restriction enregistrée");
        EventBus.$emit("reloadbooking", {});
        this.dialogs.covidInfo.dialog = false;
      });
    },

    regenMails(type) {
      this.$root
        .$confirm(
          this.$t("confirm"),
          "Attention, les emails vont être régénérés ?",
          { width: 350 }
        )
        .then((confirm) => {
          if (!confirm) {
            return Promise.resolve();
          } else {
            this.$emit("regenMails", { mailType: type, reload: false });
          }
        });
    },
    openGmail(search) {
      window
        .open(
          `https://mail.google.com/mail/u/1/?zx=jdeoquidp2rc#search/${search}`,
          "_blank"
        )
        .focus();
    },
    blockPrices() {
      const date = this._parseDate(this.dialogs.blockPrice.form.date, "dd/MM/yyyy")
      date.setHours(this.dialogs.blockPrice.form.hours, this.dialogs.blockPrice.form.minutes)
      const dateObj = this._formatDate(date, "dd/MM/yyyy HH:mm:ss")
      post(`/api/booking/${this.booking.id}/block-prices`, { expirationDate: dateObj }).res(() => {
        this.booking.blockPriceDate = dateObj
        this.dialogs.blockPrice.show = false
        EventBus.$emit("toaster-msg", this.booking.blockPriceDate ? "Blocaque enregistré" : "Déblocage enregistré");
        EventBus.$emit("reloadbooking", {});
      })
    },
    unblockPrices() {
      get(`/api/booking/${this.booking.id}/unblock-prices`).res(() => {
        EventBus.$emit("toaster-msg", "Déblocage enregistré");
        EventBus.$emit("reloadbooking", {});
      })
    },
    goToLead() {
      const url = `/lead/${this.listingLead.leads[0].id}`
      window.open(url, "_blank");
    }
  },
};
</script>

<style scoped>
.card-customer-dialog {
  min-height: 680px;
}

.missing {
  color: #aaa;
  padding-bottom: 1px;
  border-bottom: dotted 1px;
}

.action_stripe {
  background-color: #f5f5f5;
}

>>>.v-datatable thead tr {
  height: 36px;
}

.ipLocatorLink>.v-chip__content:hover {
  cursor: pointer;
}

>>>.clickable>.v-chip__content:hover {
  cursor: pointer;
}
</style>
