<template>
  <div class="comment-container">
    <v-card v-if="parsedAgencyComment" class="mb-2">
      <v-card-text class="text--primary">
        {{ parsedAgencyComment }}
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        Commentaire agence
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
    <v-card v-for="(comment, index) in sortedComments" :key="index" class="mb-2">
      <v-card-text class="text--primary d-flex justify-space-between">
        <span v-html="comment.message"></span>
        <v-menu bottom>
          <template #activator="{ on }">
            <v-btn v-on="on" icon small>
              <v-icon>more_vert</v-icon>
            </v-btn>
          </template>
          <v-list dense>
            <v-list-item @click="openEdit(comment)" style="cursor:pointer;">
              <v-list-item-title >
                Modifier
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions >
        <div class="d-flex flex-column justify-start">
        <span>Le {{ comment.creationDate }}
          par {{ comment.userName }}</span>
        <span v-if="comment.updatedBy != null">
          Mis à jour le {{ comment.updatedDate }}
        par {{ comment.updatedBy.name }}
        </span>
        </div>

        <v-spacer></v-spacer>
        <v-icon small @click="toggleStar(comment)">{{ comment.star ? 'star' : 'mdi-star-outline' }}</v-icon>
      </v-card-actions>
    </v-card>
    <smart-dialog
                title="Editer un commentaire"
                v-model="commentDialog"
                v-if="commentDialog"
                :width="800"
            >
              <smart-editor v-model="commentToEdit.message"/>
              <template slot="actions">
                <smart-btn primary @click.native="editComment(commentToEdit)"
                >Enregistrer
                </smart-btn
                >
              </template>
            </smart-dialog>
  </div>
</template>

<script>
import {get, post} from "@/utils/api";
import {EventBus} from "@/components/commons/event-bus";
import SmartEditor from "@/components/commons/SmartEditor.vue";

export default {
  name: "BookingComment",
  components: {SmartEditor},
  mixins: [],
  props: {
    bookingId: {
      type: Number,
      required: true,
    },
    comments: {
      type: Array,
      required: true,
    },
    agencyComment: {
      type: Array, required: false
    }
  },
  data() {
    return {
      commentDialog: false,
      commentToEdit:null,
      sortedComments: [],
    };
  },
  created() {
  },
  watch: {
    comments: {
      immediate: true,
      handler(newVal) {
        const arr = newVal.slice(0);

        const sort = (a, b) => {
          const aDate = this._parseDateTime(a.creationDate);
          const bDate = this._parseDateTime(b.creationDate);
          return aDate > bDate ? -1 : aDate < bDate ? 1 : 0;
        };

        const staredComments = arr
            .reduce((acc, c) => (c.star ? acc.concat(c) : acc), [])
            .sort(sort);
        const otherComments = arr
            .reduce((acc, c) => (!c.star ? acc.concat(c) : acc), [])
            .sort(sort);

        this.sortedComments = staredComments.concat(otherComments);
      },
    },
  },
  computed: {
    parsedAgencyComment() {
      return this.agencyComment != null && this.agencyComment.length > 0 ? JSON.parse(this.agencyComment[0].value) : null
    }
  },
  methods: {
    openEdit(comment){
      this.commentToEdit = comment
      this.commentDialog = true

    },
    toggleStar(comment) {
      comment.star = !comment.star;
      const url = `/api/booking/${this.bookingId}/comment/update/${comment.id}`;
      post(url, comment).res((r) => {
        EventBus.$emit("reloadbooking", {});
        return r;
      });
    },
    editComment(comment) {
      const url = `/api/booking/${this.bookingId}/comment/update/${comment.id}`;
      post(url, comment)
          .res((r) => {
            EventBus.$emit("reloadbooking", {});
            return r;
          })
          .then(() => {
            this.commentDialog = false;
          });
    },
  },
};
</script>

<style scoped>
.comment-container {
  max-height: 400px;
  overflow: auto;
}
</style>
