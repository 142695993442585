<template>
	<v-row dense class="content-pdf " v-if="invoice">
		<v-col cols="12">
			<v-row dense>
				<v-col cols="6">
					<v-row dense>
						<v-col cols="6" class="grey--text text--darken-1">{{ $t('document.invoice.place') }}:</v-col>
						<v-col cols="6">{{ invoice.invoiceDate }}</v-col>
						<v-col cols="6" class="grey--text text--darken-1">{{ $t('document.invoice.invoiceRef') }}:</v-col>
						<v-col cols="6">{{ invoice.invoice }}</v-col>
						<v-col cols="6" class="grey--text text--darken-1">{{ $t('document.invoice.bookingRef') }}:</v-col>
						<v-col cols="6">{{ invoice.bookingId }}</v-col>
						<v-col cols="6" class="grey--text text--darken-1">{{ $t('document.invoice.bookingDate') }}:</v-col>
						<v-col cols="6">{{ invoice.bookingDate }}</v-col>
					</v-row>
				</v-col>
				<v-col cols="6" v-html="invoice.customer"></v-col>

				<v-col cols="12" class="px-2" v-if="invoice.prestations && invoice.prestations.length > 0">
					<v-row dense class="tile mt-2">
						<v-col cols="12" class="head display-1 my-2 "> {{ $t('document.invoice.prestations') }}</v-col>
						<v-col cols="12" class="content body-2  pa-2 my-1">
							<v-row dense class="mt-2" v-for="(prestation, idx) in invoice.prestations" :key="idx">
								<v-col cols="9" class="font-weight-bold body-2 font-weight-medium "> {{ prestation.name }}</v-col>
								<v-col cols="3" class="text-right body-2 font-weight-medium "> {{ amount(prestation) }}</v-col>
								<v-col cols="12" v-html="prestation.details"></v-col>
							</v-row>
						</v-col>

						<v-col cols="12" class="font-weight-medium body-2 font-weight-medium  pa-3 grey lighten-1">
							<v-row dense>
								<v-col cols="9">{{ $t('document.invoice.total') }}</v-col>
								<v-col cols="3" class="text-right body-2 font-weight-medium "> {{ invoice.total }}
									{{ invoice.currency }}</v-col>
							</v-row>
							<v-row dense v-if="invoice.taxes > 0">
								<v-col offset="6" cols="6" class="text-right body-2 font-weight-medium ">
									{{ $t('document.invoice.tax', { price: invoice.taxes }) }}{{ invoice.currency }}</v-col>
							</v-row>
						</v-col>
					</v-row>
				</v-col>

				<v-col cols="12" v-if="invoice.credit">
					<v-row dense class="tile mt-2">
						<v-col cols="12" class="head display-1 ma-2 "> {{ $t('document.invoice.credit') }} </v-col>
						<v-col cols="12" class="content body-2  pa-2 ma-1">
							<v-row dense class="mt-2">

								<v-col cols="9" class="font-weight-bold body-2 font-weight-medium "> {{ invoice.credit.name }}</v-col>
								<v-col cols="3" class="text-right body-2 font-weight-medium "> {{ invoice.credit.amount }}
									{{ invoice.credit.currency }}</v-col>
							</v-row>
						</v-col>

						<v-col cols="12" class="font-weight-medium body-2 font-weight-medium  pa-3 grey lighten-1">
							<v-row dense>
								<v-col cols="9">{{ $t('document.invoice.total') }}</v-col>
								<v-col cols="3" class="text-right body-2 font-weight-medium "> {{ invoice.total }}
									{{ invoice.currency }}</v-col>
							</v-row>
						</v-col>
					</v-row>
				</v-col>

				<v-col cols="12">
					<v-row dense class="tile mt-2">
						<v-col cols="12" class="head text-h6 ma-2 ">{{ $t('document.invoice.payments') }}</v-col>

						<v-col cols="12" class="content body-2  pa-2 ma-1">
							<v-row dense v-for="p in payments">
								<v-col cols="9">
									{{ _parseAndFormatDate(p.paymentDate.substring(0, 19), "yyyy-MM-dd'T'HH:mm:ss",
									'dd/MM/yyyy') }} - 
									{{ $t('paymentTypes.'+p.paymentType) }}</v-col>
								<v-col cols="3" class="text-right body-2 font-weight-medium "> {{ p.amount }}
									{{ p.currency }}</v-col>
							</v-row>
						</v-col>


						<v-col cols="12" class="content body-2  pa-2 ma-1">
							<v-row dense>
								<v-col cols="9">{{ $t('document.invoice.total') }}</v-col>
								<v-col cols="3" class="text-right body-2 font-weight-medium "> {{ invoice.total }}
									{{ invoice.currency }}</v-col>
								<v-col cols="12" class="mt-2" v-if="invoice.payment">
									<v-row dense>
										<v-col cols="9" class="font-weight-bold body-2 font-weight-medium ">
											{{ $t('document.invoice.paid') }}
										</v-col>
										<v-col cols="3" class="text-right body-2 font-weight-medium ">
											{{ invoice.payment.totalPaidAmount }}
											{{ invoice.payment.customerCurrency }}
										</v-col>
									</v-row>
								</v-col>
							</v-row>
						</v-col>
						<v-col cols="12" class="font-weight-medium body-2 font-weight-medium  pa-3 grey lighten-1"
							v-if="invoice.payment && invoice.payment.balance > 0">
							<v-row dense v-if="invoice.isInAccount">
								<v-col cols="9"></v-col>
								<v-col cols="3" class="text-right body-2 font-weight-medium ">

								</v-col>
							</v-row>


							<v-row dense v-else>
								<v-col cols="9">{{ $tc('document.invoice.balance', invoice.balanceDate) }}</v-col>
								<v-col cols="3" class="text-right body-2 font-weight-medium ">
									{{ invoice.payment.balance }}
								</v-col>
							</v-row>
						</v-col>
						<v-col cols="12" class="font-weight-medium body-2 font-weight-medium  pa-3 grey lighten-1"
							v-if="invoice.payment && invoice.payment.balance == 0 && lastPayment">
							{{ $t('document.invoice.balanced') }}
							{{ _parseAndFormatDate(lastPayment.paymentDate.substring(0, 19), "yyyy-MM-dd'T'HH:mm:ss", 'dd/MM/yyyy') }}
							{{ getPaymentType() }}
						</v-col>
					</v-row>
				</v-col>
				<v-col cols="12" class="body-2 ">
					{{ $tc('document.invoice.tva', invoice.tva) }}<br />
					<span v-if="invoice.tva == 0">{{ $tc('document.invoice.tvaInfo') }}</span><br />

				</v-col>
			</v-row>
		</v-col>

		<span id="loaded"></span>
	</v-row>
</template>

<script>
import { get } from "@/utils/api"

export default {
	name: "Invoice",
	mixins: [],
	props: ['bookingid'],
	data() {
		return {
			invoice: null,
			flightTotalAmount: 0,
			hotelSize: 0,
			tax: 0
		}
	},
	created() {
		this.init()
	},
	watch: {},
	computed: {
		lastPayment() {
			if (this.invoice.payment && this.invoice.payment.payments.length > 0) {
				const payments = this.invoice.payment.payments
        payments.sort((a,b) => new Date(b.paymentDate) - new Date(a.paymentDate))
        return payments[0]
			}
			return null;
		},
		payments(){
			if (this.invoice.payment && this.invoice.payment.payments.length > 0) {
				return this.invoice.payment.payments
			}
			return [];
		}
	},
	methods: {
		init() {
			const invoiceid = this.$route.query.invoiceid

			var params = invoiceid ? `invoiceid=${invoiceid}` : ""
			const url = `/api/document/invoice/${this.bookingid}?${params}`

			return get(url)
				.badRequest(err => {
					console.error(err)
				}).json((data) => {
					this.$locale = data.lang || 'fr'
					this.invoice = data
					this.flightTotalAmount = this.getFlightTotalAmount()
					this.hotelSize = this.getHotelSize()
				})
		},

		getFlightTotalAmount() {
			return this.invoice.prestations.reduce((previousValue, currentValue) => {
				if (currentValue != null && currentValue.kind == 'flight') {
					return currentValue.amount + previousValue
				} else return previousValue
			}, 0)
		},

		getHotelSize() {
			return this.invoice.prestations.reduce((previousValue, currentValue) => {
				if (currentValue.kind == 'hotel')
					return previousValue.concat([currentValue])

				return previousValue
			}, []).length
		},

		amount(prestation) {
			let amount = 0
			switch (prestation.kind) {
				case 'untyped':
					amount = prestation.amount;
					break;
				case 'insurance':
					amount = prestation.amount;
					break;
				case 'transfer':
					amount = prestation.amount;
					break;
				case 'car':
					amount = prestation.amount;
					break;
				case 'flight':
					if (this.invoice.withPriceDetails) {
						amount = prestation.amount;
					} else {
						amount = null;
					}
					break;
				case 'hotel':
					if (this.invoice.hasFlights && !this.invoice.withPriceDetails) {
						amount = prestation.amount + (this.flightTotalAmount / this.hotelSize)
					} else {
						amount = prestation.amount;
					}
					break;
			}

			if (amount != null) {
				return this.$options.filters.currency(amount, prestation.currency, { maximumFractionDigits: 2 });
			}

			return amount;
		},
		getPaymentType() {
			if (!this.invoice.isInAccount) {
				return `(${this.$t(`paymentTypes.${this.lastPayment.paymentType}`)})`
			} else return ""
		}
	}
}
</script>


<style scoped src="@/assets/pdf/css/default.less" lang="less"></style>
<style scoped lang="less">
.tile {
	border: 1px solid black;

	.head {
		border-bottom: 1px dotted black;
	}
}

.content-pdf {
	height: 100.5%; //hack to avoid grey background here
}

.total {}
</style>
