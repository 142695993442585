<template>
  <v-row dense class="pa-1">
    <v-col
      cols="12"
      class="rowprestation"
      v-for="(prestation, index) in resumeTravel"
      :key="prestation ? prestation.id : index"
    >
      <v-row dense class="pa-0 ma-0" v-if="prestation">
        <v-col cols="9" class="pa-0 ma-0">
          {{ prestation.name }}
          Séjour {{ prestation.name }}
          {{ _duration(prestation.start, prestation.stop) }} nuits
        </v-col>
        <v-col cols="3" class="pa-0 ma-0" v-if="booking?.customer?.agent != null">
          {{formatNameAgent(booking.customer.agent)}}
        </v-col>
        <v-col cols="10" class="pa-0 ma-0">
          {{ _parseAndFormatLocalDateTime(booking.startDate) }} -
          {{ _parseAndFormatLocalDateTime(booking.stopDate) }} (j{{
            _durationBeforeStart(booking.startDate)
          }})
        </v-col>

        <v-col cols="2" class="pa-0 ma-0" v-if="booking?.customer?.agent != null">
          <v-btn x-small @click="seeAgency()">
            Voir l'agence
          </v-btn>
          <v-btn x-small @click="seeInHL()">
            Voir résa
          </v-btn>
        </v-col>
        <v-col cols="2" class="pa-0 ma-0" v-else>
          <v-btn x-small @click="seeInHL()">
            Voir résa
            <v-icon small>open_in_new</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-col>

    <v-col cols="12" class="my-2">
      <v-divider></v-divider>
    </v-col>

    <v-col v-if="flightAdditionnalInfos" cols="12" class="py-2">
      <v-row dense class="ml-1 prestation-content">
        <v-col cols="1" class="caption pa-0 ma-0">
            <v-avatar :color="colorVariant('FlightPrestation')" size="21">
              <v-icon size="11" dark>{{ icon('FlightPrestation') }}</v-icon>
            </v-avatar>
          </v-col>
          <v-col v-if="flightAdditionnalInfos.arrival" cols="11" class="pl-1 pa-0 ma-0">
            {{flightAdditionnalInfos.arrival.name}} - Vol n°{{flightAdditionnalInfos.arrival.flightNumber}}
            le {{flightAdditionnalInfos.arrival.date}} à
            {{flightAdditionnalInfos.arrival.hour}}:{{flightAdditionnalInfos.arrival.minute}}
          </v-col>
      </v-row>
      <v-row dense class="ml-1 mt-1 prestation-content">
        <v-col cols="1" class="caption pa-0 ma-0">
            <v-avatar :color="colorVariant('FlightPrestation')" size="21">
              <v-icon size="11" dark>{{ icon('FlightPrestation') }}</v-icon>
            </v-avatar>
          </v-col>
          <v-col v-if="flightAdditionnalInfos.departure" cols="11" class="pl-1 pa-0 ma-0">
            {{flightAdditionnalInfos.departure.name}} - Vol n°{{flightAdditionnalInfos.departure.flightNumber}}
            le {{flightAdditionnalInfos.departure.date}} à
            {{flightAdditionnalInfos.departure.hour}}:{{flightAdditionnalInfos.departure.minute}}
          </v-col>
      </v-row>
      
    </v-col>
    <v-col
      cols="12"
      v-for="(prestation, prestationIndex) in booking.prestations"
      :key="prestationIndex"
      class="py-2"
    >
      <template v-if="prestation.kind == 'RoomPrestation'">
        <v-row dense class="ml-1 prestation-content">
          <v-col cols="1" class="caption pa-0 ma-0">
            <v-avatar :color="colorVariant(prestation.kind)" size="21">
              <v-icon size="11" dark>{{ icon(prestation.kind) }}</v-icon>
            </v-avatar>
          </v-col>
          <v-col cols="11" class="pl-1 pa-0 ma-0">
            <div>{{ prestation.hotelName }}</div>
            <div>{{ prestation.name }} - {{ prestation.mealplanName }}</div>
          </v-col>
          <v-col cols="11" offset="1" class="py-0 pl-1 my-0">
            <small>
              {{ _parseAndFormatLocalDateTime(prestation.start) }} -
              {{ _parseAndFormatLocalDateTime(prestation.stop) }}
            </small>
          </v-col>
        </v-row>
      </template>

      <template v-else-if="prestation.kind == 'TransferPrestation'">
        <v-row dense class="ml-1 prestation-content">
          <v-col cols="1" class="caption pa-0 ma-0">
            <v-avatar :color="colorVariant(prestation.kind)" size="21">
              <v-icon size="11" dark>{{ icon(prestation.kind) }}</v-icon>
            </v-avatar>
          </v-col>
          <v-col cols="11" class="pl-1 pa-0 ma-0">
            <ul class="details-segment">
              <li>{{ prestation.fromName }}</li>
              <li>{{ prestation.toName }}</li>
            </ul>
          </v-col>
          <v-col cols="11" offset="1" class="py-0 pl-1 my-0">
            <small>
              {{
                _parseAndFormatLocalDateTime(
                  prestation.start,
                  datePatternConfig.fr.fullWithTime
                )
              }}
<!--              ({{
                _durationFormatted(
                  _parseDate(
                    prestation.start,
                    datePatternConfig.serverLocalDateTime
                  ),
                  _parseDate(
                    prestation.stop,
                    datePatternConfig.serverLocalDateTime
                  ), datePatternConfig.fr.time
                )
                  ? _durationFormatted(
                      _parseDate(
                        prestation.start,
                        datePatternConfig.serverLocalDateTime
                      ),
                      _parseDate(
                        prestation.stop,
                        datePatternConfig.serverLocalDateTime
                      ), datePatternConfig.fr.time
                    )
                  : "00h00"
              }})-->
            </small>
          </v-col>
        </v-row>
      </template>
      <template v-else-if="prestation.kind == 'CarrentalPrestation'">
        <v-row dense class="ml-1 prestation-content">
          <v-col cols="1" class="caption pa-0 ma-0">
            <v-avatar :color="colorVariant(prestation.kind)" size="21">
              <v-icon size="11" dark>{{ icon(prestation.kind) }}</v-icon>
            </v-avatar>
          </v-col>
          <v-col cols="11" class="pl-1 pa-0 ma-0">{{ $t("carrental") }}</v-col>
          <v-col cols="11" offset="1" class="py-0 pl-1 my-0">
            <small>
              {{
                _parseAndFormatLocalDateTime(
                  prestation.start,
                  datePatternConfig.fr.full
                )
              }} -
               {{
                _parseAndFormatLocalDateTime(
                  prestation.stop,
                  datePatternConfig.fr.full
                )
              }}
              ({{
                _duration(
                  _parseDate(prestation.start, "dd/MM/yyyy HH:mm:ss"),
                  _parseDate(prestation.stop, "dd/MM/yyyy HH:mm:ss"),
                  "days"
                )
              }} jours)
            </small>
          </v-col>
        </v-row>
      </template>
      <template v-else-if="prestation.kind == 'FlightPrestation'">
        <v-row dense class="ml-1 prestation-content">
          <v-col cols="1" class="caption pa-0 ma-0">
            <v-avatar :color="colorVariant(prestation.kind)" size="21">
              <v-icon size="11" dark>{{ icon(prestation.kind) }}</v-icon>
            </v-avatar>
          </v-col>
          <v-col cols="11" class="pl-1 pa-0 ma-0">
            {{ prestation.route.fromName }}
            <v-icon small>arrow_right_alt</v-icon>
            {{ prestation.route.toName }}
          </v-col>
          <v-col cols="11" offset="1" class="py-0 pl-1 my-0">
            <small>
              {{
                _parseAndFormatLocalDateTime(
                  prestation.start,
                  datePatternConfig.fr.fullWithTime
                )
              }}
              •
              {{
                _parseAndFormatLocalDateTime(
                  prestation.stop,
                  datePatternConfig.fr.fullWithTime
                )
              }}
              • PNR: {{ prestation.pnr }}
              • VOLS:
              <span v-for="(s,i) in prestation.route.segments" :key="i">
                <template v-if="i>0">, </template>
                {{s.airlineCode}}{{s.flightNumber}}
              </span>
            </small>
          </v-col>
        </v-row>
      </template>
    </v-col>

    <v-col cols="12" class="my-2">
      <v-divider></v-divider>
    </v-col>

    <v-col cols="6" class="rowprestation" v-if="showprices">Total</v-col>
    <v-col cols="6" class="text-right rowprestation" v-if="showprices">
      <b>{{ booking.price }}{{ $t(booking.currency + "-symbol") }}</b>
    </v-col>
  </v-row>
</template>

<script>
import {post} from "@/utils/api";
import ColorMixin from "@/components/mixins/ColorMixin";
import _ from "lodash";
import {upperCase} from "lodash/string";
import * as datePatternConfig from "date-fns";

export default {
  name: "BookingDrawerContent",
  mixins: [ColorMixin],
  props: {
    booking: {
      type: Object,
      required: true,
    },
    showprices: {
      type: Boolean,
      default: true,
      required: false,
    },
    btnToHL: {
      type: Boolean,
      default:false
    },
  },
  data() {
    return {};
  },
  created() {

  },
  watch: {},
  computed: {
    resumeTravel() {
      return _.uniqBy(
        this.booking.prestations.filter((p) => p.kind == "RoomPrestation"),
        "destination.name"
      ).map((p) => {
        if (p !== undefined && p.destination) {
          let destination =
            (typeof p.destination !== "undefined" || p.destination !== null) &&
            p.destination.name
              ? p.destination.name
              : "";
          return {
            id: p.id,
            name: destination,
            start: p.start,
            stop: p.stop,
          };
        }
      });
    },
    flightAdditionnalInfos() {
      if (
        this.booking &&
        this.booking.additionalInfos &&
        this.booking.additionalInfos.length > 0
      ) {
        const infos = this.booking.additionalInfos.find(
          (i) => i.item == "flightinfos"
        );
        return infos ? JSON.parse(infos.value) : null;
      } else return null;
    },
  },
  methods: {
    color(kind) {
      return this.prestationColor(kind) + " lighten-2";
    },
    colorVariant(kind) {
      return this.prestationColor(kind) + " lighten-3";
    },
    icon(kind) {
      switch (kind) {
        case "RoomPrestation":
          return "hotel";
        case "TransferPrestation":
          return "directions_bus";
        case "CarrentalPrestation":
          return "directions_car";
        case "FlightPrestation":
          return "flight";
        default:
          return "mdi-help";
      }
    },
    formatNameAgent(agent) {
      return `Agent: ${agent.firstname} ${upperCase(agent.lastname)}`
    },
    seeAgency(){
      this.$router.push(`/hlagons/crm/${this.booking.agencyId}`)
    },
    seeInHL(){
      window.open(
          `${window.location.origin}/api/agency/agent/${this.booking.customer.agent.id}/autolog?&rdc=account/orders/${this.booking.id}`,
          "_blank"
      );
    },
  },
};
</script>

<style scoped>
.prestation-content {
  line-height: 18px;
}

.v-card {
  background: #fff !important;
  border-left: 5px solid transparent;
}
</style>

