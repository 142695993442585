<template>
  <layout :drawerShow="_isDesktop()" :drawerWidth="350">
    <template slot="nav">
      <v-tabs>
        <v-tab >Dossiers</v-tab>
      </v-tabs>
    </template>
    <template slot="nav-tools">
      <booking-add>
        <smart-btn
          slot="activator"
          slot-scope="{ toggle }"
          @click.native="toggle"
        >
          <v-icon>mdi-folder-plus</v-icon>
          <span class="pl-1 d-none d-sm-flex">Nouveau dossier</span>
        </smart-btn>
      </booking-add>
    </template>
    <template slot="drawer">
      <v-form ref="searchform" v-if="searchform" @submit.prevent="loadData">
        <v-row no-gutters class="pt-2 pr-1">
          <v-col cols="12" class="pr-1">
            <smart-multi-select
              v-model="searchform.selectedVirtualSites"
              label="Sites"
              :items="virtualSites"
              :all-is-same-as-empty="true"
              :default-select-all="true"
            ></smart-multi-select>
          </v-col>

          <v-col cols="6" class="pr-1 mt-2">
            <smart-select
              v-model="searchform.selectedChains"
              item-text="name"
              item-value="id"
              label="Chaines"
              :default-select-all="true"
              :all-is-same-as-empty="true"
              :loading="loadingChains"
              :items="chains"
              :chip-style="{ width: '120px' }"
            ></smart-select>
          </v-col>

          <v-col cols="6" class="pr-1 mt-2">
            <smart-select
              v-model="searchform.selectedHotels"
              item-text="name"
              item-value="id"
              label="Hotels"
              :default-select-all="true"
              :all-is-same-as-empty="true"
              :loading="loadingHotels"
              :items="hotels"
              :chip-style="{ width: '120px' }"
            ></smart-select>
          </v-col>

          <v-col cols="12" class="pr-1 mt-2">
            <smart-select
              v-model="searchform.selectedDestinations"
              item-text="name"
              item-value="id"
              label="Destinations"
              :default-select-all="true"
              :all-is-same-as-empty="true"
              :loading="loadingDesti"
              :items="destinations"
              :chip-style="{ width: '120px' }"
            ></smart-select>
          </v-col>

          <v-col cols="12" class="mt-2">
            <v-subheader light class="pa-0 mb-0 mx-0 form-subheader"
              >Dates de réservation</v-subheader
            >
            <v-divider></v-divider>
          </v-col>
          <v-col class="pr-1" cols="6">
            <smart-picker
              v-model="searchform.bookingDateStart"
              :stopDate="searchform.bookingDateStop"
              :inputOpts="{
                label: $t('begin'),
                clearable: true,
                placeholder: ' ',
              }"
            ></smart-picker>
          </v-col>
          <v-col cols="6" class="pr-1">
            <smart-picker
              v-model="searchform.bookingDateStop"
              :startDate="searchform.bookingDateStart"
              :inputOpts="{
                label: $t('end'),
                clearable: true,
                placeholder: ' ',
              }"
            ></smart-picker>
          </v-col>

          <v-col cols="12">
            <v-subheader light class="pa-0 mt-5 mb-0 mx-0 form-subheader"
              >Dates de départ</v-subheader
            >
            <v-divider></v-divider>
          </v-col>
          <v-col class="pr-1" cols="6">
            <smart-picker
              v-model="searchform.tripDateStart"
              :stopDate="searchform.tripDateStop"
              :inputOpts="{
                label: $t('begin'),
                clearable: true,
                placeholder: ' ',
              }"
            ></smart-picker>
          </v-col>
          <v-col cols="6" class="pr-1">
            <smart-picker
              v-model="searchform.tripDateStop"
              :startDate="searchform.tripDateStart"
              :inputOpts="{
                label: $t('end'),
                clearable: true,
                placeholder: ' ',
              }"
            ></smart-picker>
          </v-col>

          <v-col cols="6">
            <v-subheader light class="pa-0 mt-5 mb-0 mx-0 form-subheader"
              >Statut Dossier</v-subheader
            >
            <v-divider></v-divider>

            <v-checkbox
              v-for="(bs, index) in bookingStatus"
              :key="'bs-' + index"
              v-model="searchform.bookingStatus"
              :label="bs.label"
              :value="bs.value"
              :class="'pt-1 ' + (index == 0 ? 'mt-2' : 'mt-0')"
              height="1"
            >
            </v-checkbox>
          </v-col>

        </v-row>

        <smart-btn type="submit" :loading="loading" block primary
          >Rechercher</smart-btn
        >
        <smart-btn
          block
          tertiary
          @click.native="
            clearSearchform();
            loadData();
          "
          >Réinitialiser la recherche</smart-btn
        >
      </v-form>
    </template>
    <template slot="content">
      <v-container fluid>
        <v-row justify="space-between">
          <v-col cols="8" class="">
    <BookingsByDepartureDateChart ref="bookingPerStartDate" style="max-height: 400px;"/>
          </v-col>
          <v-col cols="4">
            <WidgetHotelsTop ref="topHotelWidget" style="max-height: 400px;"/>
          </v-col>
        </v-row>
        <bookings-list-lite-table
          @loading="setLoading"
          ref="bookingListLiteTable"
        ></bookings-list-lite-table>
      </v-container>
    </template>
  </layout>
</template>

<script>
import Layout from "@/views/Layout";
import {get} from "@/utils/api";
import Vue from "vue";
import SmartSelect from "../../components/commons/SmartSelect";
import BookingAdd from "../../components/booking/BookingAdd";
import SmartMultiSelect from "../../components/commons/SmartMultiSelect";
import BookingsListLiteTable from "@/components/commons/BookingsListLiteTable.vue";
import BookingsByDepartureDateChart from "@/components/commons/widgets/BookingsByDepartureDateChart.vue";
import WidgetHotelsTop from "@/components/commons/widgets/WidgetHotelsTop.vue";

export default {
  components: {
    WidgetHotelsTop,
    BookingsByDepartureDateChart,
    BookingsListLiteTable,
    SmartMultiSelect,
    SmartSelect,
    BookingAdd,
    Layout,
  },
  mixins: [],
  data() {
    return {
      dialog: false,
      //searchform
      defaultSearchform: {
        selectedSites: [],
        selectedVirtualSites: [],
        selectedNetworks: [],
        selectedAgencies: [],
        selectedChains: [],
        selectedHotels: [],
        selectedDestinations: [],
        client: null,
        ref: null,
        bookingDateStart: this._nowFormatted("dd/MM/yyyy"),
        bookingDateStop: this._nowFormatted("dd/MM/yyyy"),
        tripDateStart: null,
        tripDateStop: null,
        bookingStatus: ["booked"],
        supplierStatus: [],
        paymentStatus: null,
        tags: [],
      },

      searchform: null,

      //
      loadingAgencies: false,
      loadingChains: false,
      loadingHotels: false,
      loadingDesti:false,
      searchAgencyInput: "",
      //
      valid: false,
      sites: [],
      virtualSites: [],
      destinations:[],
      chains: [],
      hotels: [],
      networks: [],
      agencies: [],
      agencyName: "",
      tags: [],

      bookingStatus: [
        { value: "quotation", label: "Devis" },
        { value: "booked", label: "Réservation" },
        { value: "cancelled", label: "Annulé" },
      ],
      supplierStatus: [
        { value: "ondemand", label: "En attente" },
        { value: "confirmed", label: "Confirmé" },
        { value: "refused", label: "Refusé" },
      ],

      loading: false,

      //------
      widgetBus: new Vue(),
    };
  },
  created() {

    const loadChains = get("/api/search/chains").json((chains) => {
      this.chains = chains;
    });

    const loadHotels = get("/api/search/hotels").json((hotels) => {
      this.hotels = hotels;
    });

    const loadDestinations = get("/api/search/destinations").json((destinations) => {
      this.destinations = destinations
    })

    const loadVirtualSites = get("/api/search/virtual-sites").json((sites) => {
      this.virtualSites = sites;
      this.defaultSearchform.selectedVirtualSites = this.virtualSites.map(
        (s) => s.id
      );
    });

    Promise.all([
      loadVirtualSites,
      loadChains,
      loadHotels,
      loadDestinations,
    ]).then(() => {
      this.initSearchform();
      this.loadData();
    });
  },
  mounted() {},
  computed: {},
  watch: {
    agencyName(newVal) {
      newVal &&
        get("/api/agency/search?search=" + newVal).json((agencies) => {
          this.agencies = agencies.map((agt) => {
            return {
              id: agt.id,
              name: `${agt.name} ${agt.address.city} ${agt.address.postalcode}`,
            };
          });
        });
    },
  },
  methods: {
    loadData() {
      if (!this.loading) {
        //set qs
        window.history.pushState(
          "",
          "",
          "?search=" + JSON.stringify(this.searchform)
        );

        this.getBookings(this.searchform);
        this.getWidgets(this.searchform);
      }
    },
    getWidgets(form) {
      this.$refs.bookingPerStartDate.load("/api/widget/dashboard/bookings-by-departure", form)
      this.$refs.topHotelWidget.load("/api/widget/dashboard/top-hotel", form)
    },
    getBookings(form) {
      this.$refs.bookingListLiteTable.load("/api/widget/bookings", form);
    },
    initSearchform() {
      const qs = new URLSearchParams(window.location.search);
      const search = (() => {
        try {
          return JSON.parse(qs.get("search"));
        } catch (e) {
          return null;
        }
      })();

      if (search) {
        this.searchform = search;
      } else this.clearSearchform();
    },
    clearSearchform() {
      this.searchform = { ...this.defaultSearchform };
      // /!\ black magic /!\ do not remove /!\
      this.$nextTick(() => {
        this.searchform.tripDateStart = null;
        this.searchform.tripDateStop = null;
      });
    },
    setLoading(loading) {
      this.loading = loading;
    },
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.v-subheader {
  width: 100%;
  height: 20px;
  color: #616161;
}

td:hover {
  cursor: pointer;
}
</style>
