<template>
  <v-card :elevation="1"
          class="pa-4"
          style="height:100%; width:100%">
    <div class="font-weight-bold text-lg">
      Top 10 hôtels
    </div>
    <apexchart
        ref="apexcharts"
        type="donut"
        height="350"
        :options="options"
        :series="options.series"
        v-if="options.series.length > 0"
        class="my-auto"
    ></apexchart>
  </v-card>
</template>
<script>
import {post} from "@/utils/api";
import ColorMixin from "@/components/mixins/ColorMixin";

export default {
  name: "WidgetHotelsTop",
  mixins: [ColorMixin],
  data(vm) {
    return {
      topHotelUrl: "/api/widget/dashboard/top-hotel",
      defaultOptions: {
        plotOptions: {
          pie: {
            customScale: 1,
            donut: {
              size: '45%',
              labels: {
                show: false,
                name: {
                  show:true
                },
                value: {
                  show:true
                }
              }
            }
          }
        },
        series: [],
        labels: [],

        legend: {
          position: 'top',
        },
        dataLabels: {
          enabled: true,
        },
        tooltip: {
          custom: {}
        }

      },
      options: null
    }
  },
  mounted() {
    this.options = this.defaultOptions
  },
  methods: {
    load(url, form) {
      this.options = this.defaultOptions
      this.options.series = [];
      this.options.labels = [];
      post(url ?? this.topHotelUrl, form, {"Content-Type": "application/json"})
          .json(rs => {


            let total = 0
            for (let i = 0; i < rs.length; i++) {
              total += rs[i].sales
            }
            rs.forEach((hotel) => {
              this.options.series.push(hotel.sales)
              this.options.labels.push(hotel.name)

            })

          })
      const self = this
      this.options.tooltip.custom = function ({series, seriesIndex, dataPointIndex, w}) {
        const value = self._formatCurrency(series[seriesIndex], 'EUR')
        return `<div class="apexcharts-tooltip-series-group apexcharts-active" style="order: 1; display: flex;" >
                    <div class="apexcharts-tooltip-text" style="font-size: 12px;">
                      <div class="apexcharts-tooltip-y-group d-flex flex-row justify-space-between">
                        <span class="apexcharts-tooltip-text-y-label">${w.config.labels[seriesIndex]} </span>
                        <span class="apexcharts-tooltip-text-y-value">${value}</span>
                      </div>
                    </div>
                  </div>`
      }


    },
  }
}
</script>