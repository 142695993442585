<template>
  <v-card>
    <v-card-title>
      <v-icon>money</v-icon>
      {{ $t("price-computing") }}
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="3">
          <div>
            <span class="body-2 font-weight-medium">{{ $t("sell") }}:</span>
            {{ prestation.price }} {{ $t(prestation.currency + "-symbol") }}
            <span v-if="prestation.touristTax">
              (<span class="body-2 font-weight-medium">Taxe de séjour: </span>{{ prestation.touristTax | currency("EUR") }})
            </span>
            <span v-if="prestation.euroPrice != prestation.price"
              >({{ prestation.euroPrice }}€ @{{
                prestation.markup | percent
              }})</span
            >
          </div>
          <div>
            <span class="body-2 font-weight-medium">{{ $t("buy") }}:</span>
            {{ prestation.purchase }}
            {{ $t(prestation.purchaseCurrency + "-symbol") }}
            <span v-if="prestation.euroPurchase != prestation.purchase"
              >({{ prestation.euroPurchase }}€ @{{
                prestation.euroPurchaseRate
                  | currency(prestation.purchaseCurrency)
              }})</span
            >
            <span
              v-if="
                prestation.realEuroPurchase &&
                prestation.euroPurchase != prestation.realEuroPurchase
              "
            >
              <span class="body-2 font-weight-medium">{{ $t("real") }}:</span>
              {{ prestation.realEuroPurchase }}
            </span>
          </div>
          <div>
            <span class="body-2 font-weight-medium">{{ $t("markup") }}:</span>
            {{ prestation.markup | percent }}
          </div>
          <div v-if="prestation.rawPurchase">
            <span class="body-2 font-weight-medium"
              >{{ $t("amadeus-purchase") }}:</span
            >
            {{ prestation.rawPurchase | currency("EUR") }}
          </div>
          <div v-if="prestation.taxes">
            <span class="body-2 font-weight-medium">{{ $t("taxes") }}:</span>
            {{ prestation.taxes | currency("EUR") }}
          </div>
        </v-col>
        <v-col cols="3">
          <div v-if="prestation.contract">
            <span class="body-2 font-weight-medium">{{ $t("contract") }}:</span>
            {{ prestation.contract }}
            <v-btn small @click="debugHotel()">{{
              $t("see-price-computing")
            }}</v-btn>

            <smart-dialog
              v-model="debug.hotel.dialog"
              :width="1200"
              title="Détail du calcul du prix"
            >
              <iframe
                id="hoteliframe"
                @load="sendHotelData($event)"
                style="width: 100%; height: 800px"
                frameborder="0"
                :src="smartpriceUrl + '/#/hotel/price'"
              ></iframe>
            </smart-dialog>
          </div>
          <div v-if="prestation.market">
            <span class="body-2 font-weight-medium">{{ $t("market") }}:</span>
            {{ prestation.market }}
          </div>

          <div v-if="prestation.pnr">
            <v-btn small @click="debug.pnr.dialog = true">{{
              $t("see-price-computing")
            }}</v-btn>

            <smart-dialog
              v-model="debug.pnr.dialog"
              :width="1200"
              title="Debug PNR"
            >
              <iframe
                @load="sendPNR($event)"
                style="width: 100%; height: 800px"
                frameborder="0"
                :src="smartpriceUrl + '/#/pnr/price'"
              ></iframe>
            </smart-dialog>
          </div>
        </v-col>
        <v-col cols="3">
          <div class="body-2 font-weight-medium">{{ $t("extras") }}</div>
          <ul
            v-if="
              prestation.data &&
              prestation.data.extras &&
              prestation.data.extras.length > 0
            "
          >
            <li
              v-for="extra in prestation.data.extras"
              :key="extra.id"
            >
              <div class="d-flex align-center ">
              <v-checkbox disabled :value="extra.included"/>{{ extra.name }}
              <template v-if="extra.mandatory"> (obligatoire) </template> ({{
                extra.price
                }}€)
                </div>
            </li>
          </ul>
          <div v-else>
            <i>{{ $t("no-extras") }}</i>
          </div>
        </v-col>
        <v-col cols="3">
          <div class="body-2 font-weight-medium">{{ $t("options") }}</div>
          <ul
            v-if="
              prestation.data &&
              prestation.data.offers &&
              prestation.data.offers.length > 0
            "
          >
            <li v-for="offer in prestation.data.offers" :key="offer.id">
              {{ offer.name }}
            </li>
          </ul>

          <ul
            v-else-if="
              prestation.data &&
              prestation.data.options &&
              prestation.data.options.length > 0
            "
          >
            <li v-for="option in prestation.data.options" :key="option.id">
              {{ option.name }}
            </li>
          </ul>
          <div v-else>
            <i>{{ $t("no-offers") }}</i>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
//import _ from 'lodash'
import { get } from "@/utils/api";
export default {
  name: "BookingPriceComputing",
  props: {
    booking: {
      type: Object,
      required: true,
    },
    prestation: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    debug: {
      hotel: {
        dialog: false,
        data: null,
      },
      pnr: {
        dialog: false,
      },
    },
    auth: {
      user: "smartprice",
      key: "04fd36ead89502bd19c1bdf4e19b1e95",
    },
  }),
  computed: {
    smartpriceUrl() {
      //return 'http://localhost:8081/'
      return "https://smartprice.smart-be.com";
    },
  },
  methods: {
    debugHotel() {
      get(
        `/api/prestation/debug/hotel?prestationId=` + this.prestation.id
      ).json((data) => {
        this.debug.hotel.data = data;
        this.debug.hotel.dialog = true;
      });
    },
    sendHotelData($event) {
      const target = $event.target;
      setTimeout(() => {
        target.contentWindow.postMessage(
          {
            auth: this.auth,
            hoteldebugprice: this.debug.hotel.data,
          },
          "*"
        );
      }, 1000);
    },
    sendPNR($event) {
      const target = $event.target;
      setTimeout(() => {
        target.contentWindow.postMessage(
          {
            auth: this.auth,
            debugpnr: {
              siteId: this.booking.context.site.id,
              priceClassTypeId: this.booking.context.priceClassType.id,
              pnr: this.prestation.pnr,
            },
          },
          "*"
        );
      }, 1000);
    },
  },
};
</script>

<style scoped></style>
