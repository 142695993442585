import IncentiveRecap from "@/views/hlagons/incentives/IncentiveRecap";
import GiftCardsList from "@/views/hlagons/incentives/GiftCardsList";
import Deduplicates from "@/views/hlagons/deduplicates/Deduplicates";
import HomeHL from "@/views/hlagons/dashboard/HomeHL.vue";
import Crm from "@/views/hlagons/Crm";
import AgentActivity from "@/views/hlagons/AgentActivity";
import CommercialLogList from '@/views/hlagons/CommercialLogList'
import CrmAgencyDetails from '@/views/stats/CrmAgencyDetails'
import Noop from '@/components/Noop'
import SuiviAgence from "@/views/hlagons/SuiviAgence.vue";
import Networks from "@/views/hlagons/Networks.vue";
import PaymentTerms from "@/views/hlagons/PaymentTerms.vue";
import CommercialListingHL from "@/views/hlagons/CommercialListingHL.vue";
import HlStats from "@/views/stats/HlStats.vue";

const HLRoutes = [

	{
		path: '/hlagons', component: Noop,
		children: [
			{
				path: '/',
				name: 'HLagons',
				component: HomeHL,
				props: true,
				meta: {
				}
			},
			{
				path: 'listing',
				name: 'CommercialListingBookingHL',
				component: CommercialListingHL,
				props:true,
				meta:{}
			},
			{
				path: 'incentives',
				name: 'IncentiveRecap',
				component: IncentiveRecap,
				props: true,
				meta: {
					breadcrumb: [
						{name: 'Hotels & lagons', route:{name:'IncentiveRecap'}},
						{name: 'Récapitulatif incentives'},
					]
				}
			},
			{
				path: 'giftcards',
				name: 'GiftCards',
				component: GiftCardsList,
				props: true,
				meta: {
					breadcrumb: [
						{name: 'Hotels & lagons', route:{name:'IncentiveRecap'}},
						{name: 'Chèques-cadeaux'},
					]
				}
			},
			{
				path: 'crm',
				name:'Crm',
				component: Crm,
				props: true,
				meta: {
					breadcrumb: [
						{name: 'Hotel & lagons', route: {name: 'IncentiveRecap'}},
						{name: 'Crm'}
					]
				}
			},
			{
				path: 'crm/:id',
				name:'CrmAgencyDetails',
				component: CrmAgencyDetails,
				props: true,
				meta: {
					breadcrumb: [
						{name: 'Hotel & lagons', route: {name: 'IncentiveRecap'}},
						{name: 'Crm', route: {name: 'Crm'}},
						{name: 'Détails'}
					]
				}
			},
			{
				path: 'agent-activity',
				name:'AgentActivity',
				component: AgentActivity,
				props: false,
				meta: {
					
				}
			},{
				path: 'commercial-log',
				name:'CommercialLog',
				component: CommercialLogList,
				props: false,
				meta: {

				}
			},
			{
				path: 'deduplicates',
				name:'Deduplicates',
				component: Deduplicates,
				props: true,
				meta: {
					
				}
			},
			{
				path: 'suivi-agence',
				name:'Suivi Agence',
				component: SuiviAgence,
				props: true,
				meta: {

				}
			},
			{
				path: 'networks',
				name:'networks',
				component: Networks,
				props: true,
				meta: {

				}
			},
			{
				path: 'payment-terms',
				name:'PaymentTerms',
				component: PaymentTerms,
				props: true,
				meta: {

				}
			},
			{
				path: 'dashboard',
				name: 'HomeHL',
				component: HomeHL,
				props: false,
			},
		]
	}

]

export default HLRoutes