<template>
  <div class="grey lighten-3">
    <v-toolbar>
      <v-container fluid class="pa-0">
        <v-row dense>
          <v-col cols="3" class="px-3" align-self="center">
            <v-autocomplete
                v-model="searchSelect"
                :items="items"
                :append-icon="searchSelect ? '' : 'search'"
                :clearable="searchSelect != ''"
                :search-input.sync="searchTerm"
                hide-no-data
                hide-details
                item-text="name"
                item-value="id"
                placeholder="Hotels, Iles, Destinations"
                return-object
                no-filter
                dense
            >
              <template slot="item" slot-scope="data">
                <v-list-item-action>
                  <v-icon>{{ searchTermIcon(data.item.kind) }}</v-icon>
                </v-list-item-action>
                <v-list-item-content
                    v-text="data.item.name"
                ></v-list-item-content>
              </template>
              <template slot="selection" slot-scope="data">
                <v-icon>{{ searchTermIcon(data.item.kind) }}</v-icon>
                <span class="ml-2">{{ data.item.name }}</span>
              </template>
            </v-autocomplete>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="1" class="px-3" align-self="center">
            <smart-picker
                v-model="ctx.startDate"
                :next-picker="$refs.bookingStopDatePicker"
                :inputOpts="{ label: $t('begin') }"
            ></smart-picker>
          </v-col>
          <v-col cols="1" class="px-3" align-self="center">
            <v-select
                v-model="nbNights"
                :items="nights"
                label="Nuits"
                class="fill-height"
                hide-details
            ></v-select>
          </v-col>
          <v-col cols="1" class="px-3" align-self="center">
            <smart-picker
                v-model="ctx.stopDate"
                ref="bookingStopDatePicker"
                :startDate="ctx.startDate"
                format="dd/MM/yyyy"
                :min="ctx.startDate"
                :inputOpts="{ label: $t('end') }"
            ></smart-picker>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="1" class="px-3" align-self="center">
            <room-guests-repartition
                v-model="ctx.roomsRepartition"
                :guests="booking.globalGuests"
            ></room-guests-repartition>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="2" class="px-3 text-right" align-self="center">
            <v-btn
                v-if="!searchTerm && !searchSelect"
                @click.native="searchHotels"
                :loading="loading"
                disabled
            >
              <v-icon>search</v-icon>
              Rechercher
            </v-btn>
            <v-btn v-else @click.native="searchHotels" :loading="loading">
              <v-icon>search</v-icon>
              Rechercher
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-toolbar>

    <v-container fluid class="pa-0 pt-1">
      <v-row dense justify="end">
        <v-col class="mt-1 pa-1" cols="9" md="8">
          <!---------- filtres ---------->
          <v-col class="mb-2 pa-0" cols="12">
            <v-row dense class="xs12" align="center" justify="space-between">
              <v-col class="row justify-space-between align-center" cols="6">
                <v-subheader>{{
                    $tc("findHotels", filteredHotels.length, {
                      size: filteredHotels.length,
                    })
                  }}
                </v-subheader>
              </v-col>
              <v-col class="mr-2" cols="4">
                <v-row
                    dense
                    class="xs12"
                    v-if="filteredHotels.length > 1"
                    justify="space-between"
                >
                  <v-subheader>Tri</v-subheader>
                  <v-select
                      xs-6
                      v-model="selectfilter"
                      @change="sortHotels($event)"
                      item-value="value"
                      :items="filtersSelect"
                      solo
                      hide-details
                      dense
                  ></v-select>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
          <!---------- end filtres ---------->

          <hotel-price
              class="mb-2"
              v-for="hotelprice in filteredHotels"
              :key="'hotelprice' + hotelprice.hotel.id"
              :bookingId="booking.id"
              :bookingPrice="booking.price"
              :hotelprice="hotelprice"
              :ctx="ctx"
          ></hotel-price>
        </v-col>
        <v-col
            class="mt-1 pa-1"
            cols="3"
            md="2"
            v-if="hotels && hotels.length > 1"
        >
          <v-card class="elevation-2">
            <v-card-text>
              <v-row dense>
                <v-col cols="12">
                  <v-subheader class="pl-0">Hotel</v-subheader>
                  <v-text-field
                      label="Hotel"
                      solo
                      v-model="filters.hotelName"
                      @input="applyFilters"
                      hide-details
                      append-icon="search"
                  />
                </v-col>

                <v-col cols="12" class="text-center ma-1">
                  <v-subheader class="pl-0">Catégorie</v-subheader>
                  <v-row dense>
                    <v-col cols="4" v-for="(r, i) in ratings" :key="i">
                      <v-checkbox
                          :key="i"
                          class="mt-0"
                          v-model="filters.hotelRatings"
                          :label="r.label"
                          :value="r.value"
                          hide-details
                          @change="applyFilters"
                      ></v-checkbox>
                    </v-col>
                  </v-row>
                </v-col>

                <v-col cols="12" class="text-center ma-1">
                  <v-subheader class="pl-0"
                  >Prix ({{ filters.price.min }} -
                    {{ filters.price.max }})
                  </v-subheader
                  >
                  <v-range-slider
                      v-model="filters.price.range"
                      :min="filters.price.min"
                      :max="filters.price.max"
                      :step="100"
                      thumb-label
                      @change="applyFilters"
                      hide-details
                  ></v-range-slider>
                </v-col>
                <v-col cols="12" class="text-center ma-1">
                  <v-subheader class="pl-0">Pension</v-subheader>
                  <v-row dense>
                    <v-col cols="6" v-for="(m, i) in meals" :key="i">
                      <v-checkbox
                          class="mt-0"
                          v-model="filters.meals"
                          :label="m.label"
                          :value="m.mealPlanTypeId"
                          hide-details
                          @change="applyFilters"
                      ></v-checkbox>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" class="text-center ma-1">
                  <v-subheader class="pl-0">Activités</v-subheader>
                  <v-row dense>
                    <v-col cols="6" v-for="(o, i) in activities" :key="i">
                      <v-checkbox
                          class="mt-0"
                          v-model="filters.activities"
                          :label="o"
                          :value="o"
                          hide-details
                          @change="applyFilters"
                      ></v-checkbox>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" class="text-center ma-1">
                  <v-subheader class="pl-0">Options</v-subheader>
                  <v-row dense>
                    <v-col cols="6" v-for="(o, i) in roomItems" :key="i">
                      <v-checkbox
                          class="mt-0"
                          v-model="filters.roomItems"
                          :label="o"
                          :value="o"
                          hide-details
                          @change="applyFilters"
                      ></v-checkbox>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" class="text-center ma-1">
                  <v-subheader class="pl-0">Lieux</v-subheader>
                  <v-row dense>
                    <v-col cols="6" v-for="(o, i) in locations" :key="i">
                      <v-checkbox
                          class="mt-0"
                          v-model="filters.locations"
                          :label="o"
                          :value="o"
                          hide-details
                          @change="applyFilters"
                      ></v-checkbox>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <!---------- prestation recap ---------->
        <v-col class="mt-1 pa-1" cols="2" v-if="sumPrestation">
          <v-card xs9>
            <booking-drawer-content
                v-if="booking"
                :booking="booking"
            ></booking-drawer-content>
          </v-card>
        </v-col>
        <!---------- end prestation recap ---------->
      </v-row>
    </v-container>
  </div>
</template>
<script>
import HotelPrice from "@/components/commons/HotelPrice";
import {EventBus} from "@/components/commons/event-bus";
import {get, post} from "@/utils/api";
import _ from "lodash";
import BookingDrawerContent from "@/components/commons/BookingDrawerContent";
import RoomGuestsRepartition from "../../commons/RoomGuestsRepartition";

export default {
  name: "AddHotel",
  components: {
    RoomGuestsRepartition,
    HotelPrice,
    BookingDrawerContent,
  },
  props: {
    booking: {required: true},
    sumPrestation: {required: true},
    lastPrestationDate: {required: true},
  },
  data() {
    return {
      loading: false,
      items: [],
      searchTerm: null,
      searchSelect: null,
      startMenu: false,
      stopMenu: false,
      startDateFormatted: null,
      stopDateFormatted: null,
      nights: [],
      hotels: null,
      selectfilter: {sortType: "price", sortDirection: "asc"},
      filtersSelect: [
        {
          value: {sortType: "price", sortDirection: "asc"},
          text: "Prix croissant",
        },
        {
          value: {sortType: "price", sortDirection: "desc"},
          text: "Prix décroissant",
        },
        {
          value: {sortType: "rating", sortDirection: "asc"},
          text: "Nombre d'étoiles croissant",
        },
        {
          value: {sortType: "rating", sortDirection: "desc"},
          text: "Nombre d'étoiles décroissant",
        },
      ],
      filteredHotels: [],
      adultOpts: [],
      childOpts: [],
      ctx: {
        hotelId: 0,
        bookingDate: "",
        startBookingDate: "",
        stopBookingDate: "",
        startDate: "",
        stopDate: "",
        pickerStopDate: "",
        roomsRepartition: [{index: 0, guests: [], repartition: "SAMEROOM"}],
        declarations: [],
        roomTypeIds: [],
        mealPlanTypeIds: [],
        extras: [],
        hotelSelections: [],
        env: {
          siteId: 1,
          langId: 1,
          priceClassTypeId: 2,
          strokePriceClassTypeId: 3,
          clientCountry: "FR",
          debug: false,
          withCache: false,
        },
      },
      ratings: [
        {value: 1, label: "1 *"},
        {value: 2, label: "2 *"},
        {value: 3, label: "2 * sup"},
        {value: 4, label: "3 *"},
        {value: 5, label: "3 * sup"},
        {value: 6, label: "4 *"},
        {value: 7, label: "4 * sup"},
        {value: 8, label: "5 *"},
        {value: 9, label: "5 * luxe"},
        {value: 10, label: "6 * luxe"},
        {value: 0, label:"Non noté"}
      ],
      meals: [
        {mealPlanTypeId: 4, label: "Hébergement seul"},
        {mealPlanTypeId: 3, label: "Petit-déjeuner"},
        {mealPlanTypeId: 1, label: "Demi-pension"},
        {mealPlanTypeId: 2, label: "Pension complète"},
        {mealPlanTypeId: 5, label: "All inclusive"},
      ],
      activities: [],
      roomItems: [],
      locations: [],
      filters: {
        hotelName: "",
        hotelRatings: [],
        price: {
          range: [0, 5000],
          min: -1,
          max: -1,
        },
        meals: [],
        activities: [],
        roomItems: [],
        locations: [],
      },
      isDisabled: true,
    };
  },
  created() {
    this.ctx.bookingDate = this._nowFormatted(
        this.datePatternConfig.serverLocalDateTime
    );
    this.ctx.startDate = this.startBookingDate;
    this.ctx.stopDate = this.stopBookingDate;
    this.ctx.env = {
      ...this.ctx.env,
      ...{
        siteId: this.booking.context.site.id,
        langId: this.booking.context.lang.id,
        clientCountry: this.booking.context.countryCode,
        priceClassTypeId: this.booking.context.priceClassType.id,
        strokePriceClassTypeId: this.booking.context.strokePriceClassType.id,
      },
    };
    this.ctx.hotelSelections = this.booking.prestations
        .filter(
            (p) => p.kind === "RoomPrestation" && p.status === this.booking.status
        )
        .map((p) => {
          return {
            hotelId: p.hotelId,
            rooms: [
              {
                roomTypeId: p.roomTypeId,
                mealPlanTypeId: p.data.meal.mealPlanTypeId,
                startDate: p.start.substring(0, 10),
                stopDate: p.stop.substring(0, 10),
                roomId: p.data.room.id,
              },
            ],
          };
        });
    this.ctx.declarations = this.booking.context.declarations;

    for (var n = 1; n <= 30; n++) {
      this.nights.push({value: n, text: n + " Nuit" + (n > 1 ? "s" : "")});
    }

    this.ctx.roomsRepartition[0] = {
      index: 0,
      guests: this.booking.globalGuests,
      repartition: "SAMEROOM",
    };
  },
  watch: {
    searchTerm: _.debounce(function (val) {
      val && val !== this.searchSelect && this.doSearchTerm(val);
    }, 300),
  },
  computed: {
    nbNights: {
      get() {
        const start = this._parseDate(
            this.ctx.startDate,
            this.datePatternConfig.serverLocalDate
        );
        const stop = this._parseDate(
            this.ctx.stopDate,
            this.datePatternConfig.serverLocalDate
        );
        return this._duration(start, stop, "days");
      },
      set(nights) {
        this.ctx.stopDate = this._formatDate(
            this._addDays(
                this._parseDate(
                    this.ctx.startDate,
                    this.datePatternConfig.serverLocalDate
                ),
                nights
            ),
            this.datePatternConfig.serverLocalDate
        );
      },
    },
    startBookingDate: function () {
      if (
          typeof this.lastPrestationDate !== "undefined" &&
          this.lastPrestationDate !== null
      ) {
        return this.lastPrestationDate.substring(0, 10);
      } else if (this.lastPrestationDate === undefined) {
        if (
            this.booking &&
            this.booking.prestations &&
            this.booking.prestations.length == 0
        ) {
          return this.ctx.bookingDate.substring(0, 10);
        } else if (this.booking) {
          return this.booking.stopDate.substring(0, 10)
              ? this.booking.stopDate.substring(0, 10)
              : this.ctx.bookingDate;
        }
      }
      return null;
    },
    stopBookingDate: {
      get() {
        return this._formatDate(
            this._addDays(this._parseDate(this.startBookingDate), 7),
            this.datePatternConfig.serverLocalDate
        );
      },
    },
  },
  methods: {
    adults(guests) {
      return guests.filter((g) => g.ageClassType == "Adult");
    },
    childs(guests) {
      return guests.filter((g) => g.ageClassType != "Adult");
    },
    searchHotels() {
      this.loading = true;
      post(`/api/prestation/add/search`, {
        search: this.searchSelect,
        ctx: this.ctx,
      })
          .badRequest((err) => {
            this.loading = false;
            EventBus.$emit("toaster-error", err);
          })
          .json((data) => {
            this.hotels = data;

            this.activities = [];
            this.roomItems = [];
            this.locations = [];

            _.map(this.hotels, (h) => {
              this.activities = _.uniq(
                  _.concat(this.activities, h.hotel.services)
              );

              this.locations = _.uniq(_.concat(this.locations, h.hotel.tags));

              _.map(h.hotel.propositions, (p) => {
                if (
                    p.prices &&
                    (this.filters.price.min < 0 ||
                        p.prices.price < this.filters.price.min)
                ) {
                  this.filters.price.min = p.prices.price;
                }
                if (
                    p.prices &&
                    (this.filters.price.max < 0 ||
                        p.prices.price > this.filters.price.max)
                ) {
                  this.filters.price.max = p.prices.price;
                }
              });

              this.roomItems = _.uniq(
                  _.concat(
                      this.roomItems,
                      _.flatMap(h.hotel.rooms, (r) => {
                        var items = [];
                        if (r.view) items.push(r.view);
                        if (r.arguments) items.push(r.arguments);
                        r.services.map((s) => items.push(s.name));
                        return items;
                      })
                  )
              );
            });
            this.filters.price.range = [
              this.filters.price.min,
              this.filters.price.max,
            ];

            this.applyFilters();
          })
          .then(() => {
            this.loading = false;
          });
    },
    searchTermIcon(kind) {
      switch (kind) {
        case "destination":
          return "mdi-flag-variant";
        case "hotel":
          return "hotel";
        case "tag":
          return "place";
        case "island":
          return "mdi-beach";
        default:
          return "adb";
      }
    },
    doSearchTerm(term) {
      get(`/api/prestation/add/search-term?term=` + term)
          .badRequest((err) => {
            EventBus.$emit(
                "toaster-msg",
                JSON.parse(err.null).errors.map((e) => JSON.parse(e))
            );
          })
          .json((data) => {
            this.items = data;
          });
    },
    applyFilters() {
      Promise.all([
        this.filterByName(),
        this.filterByRating(),
        this.filterByPrice(),
        this.filterByMeal(),
        this.filterByActivities(),
        this.filterByRoomItems(),
        this.filterByLocation(),
      ]).then((results) => {
        this.filteredHotels = _.intersectionBy(...results, "hotel.id");
        this.sortHotels();
      });
    },
    filterByName() {
      return new Promise((resolve) => {
        if (this.filters.hotelName === "") {
          resolve(this.hotels);
        } else {
          const hotelNameLC = _.lowerCase(this.filters.hotelName);
          resolve(
              _.filter(this.hotels, (h) => {
                return _.includes(_.lowerCase(h.hotel.name), hotelNameLC);
              })
          );
        }
      });
    },
    filterByRating() {
      return new Promise((resolve) => {
        if (this.filters.hotelRatings.length === 0) {
          resolve(this.hotels);
        } else {
          resolve(
              _.filter(this.hotels, (h) => {
                return _.includes(
                    this.filters.hotelRatings,
                    h.hotel.rating.value
                );
              })
          );
        }
      });
    },
    filterByPrice() {
      return new Promise((resolve, reject) => {
        if (
            this.filters.price.range[0] === this.filters.price.min &&
            this.filters.price.range[1] === this.filters.price.max
        ) {
          resolve(this.hotels);
        } else {
          resolve(
              _.filter(this.hotels, (h) => {
                return (
                    _.findIndex(h.hotel.propositions, (p) => {
                      return (
                          p.prices &&
                          p.prices.price >= this.filters.price.range[0] &&
                          p.prices.price <= this.filters.price.range[1]
                      );
                    }) !== -1
                );
              })
          );
        }
      });
    },
    filterByMeal() {
      return new Promise((resolve, reject) => {
        if (this.filters.meals.length === 0) {
          resolve(this.hotels);
        } else {
          resolve(
              _.filter(this.hotels, (h) => {
                return (
                    _.intersection(
                        _.map(h.hotel.meals, (m) => m.mealPlanTypeId),
                        this.filters.meals
                    ).length > 0
                );
              })
          );
        }
      });
    },
    filterByActivities() {
      return new Promise((resolve, reject) => {
        if (this.filters.activities.length === 0) {
          resolve(this.hotels);
        } else {
          resolve(
              _.filter(this.hotels, (h) => {
                return (
                    _.intersection(h.hotel.services, this.filters.activities)
                        .length > 0
                );
              })
          );
        }
      });
    },
    filterByRoomItems() {
      return new Promise((resolve, reject) => {
        if (this.filters.roomItems.length === 0) {
          resolve(this.hotels);
        } else {
          resolve(
              _.filter(this.hotels, (h) => {
                const allitems = _.flatMap(h.hotel.rooms, (r) => {
                  var items = [];
                  if (r.view) items.push(r.view);
                  if (r.arguments) items.push(r.arguments);
                  r.services.map((s) => items.push(s.name));
                  return items;
                });

                return (
                    _.intersection(allitems, this.filters.roomItems).length > 0
                );
              })
          );
        }
      });
    },
    filterByLocation() {
      return new Promise((resolve, reject) => {
        if (this.filters.locations.length === 0) {
          resolve(this.hotels);
        } else {
          resolve(
              _.filter(this.hotels, (h) => {
                return (
                    _.intersection(h.hotel.tags, this.filters.locations).length > 0
                );
              })
          );
        }
      });
    },
    removeNullValue(list) {
      let propositionsHotel = list.hotel.propositions
          .reduce((acc, curr) => {
            if (curr.prices != null && curr.prices.price != null) {
              acc.push(curr);
            }
            return acc;
          }, [])
          .map((proposition) => {
            return proposition.prices.price;
          });
      return propositionsHotel;
    },
    separateNull(hotels) {
      const {withValue, withoutValue} = hotels.reduce(
          (accumulator, currentValue) => {
            const toRemove =
                currentValue.hotel.propositions
                    .map((p) => {
                      return p.prices === null || p.prices.price == null;
                    })
                    .filter((x) => x).length ==
                currentValue.hotel.propositions.length;

            if (toRemove || currentValue.hotel.propositions.length == 0) {
              accumulator.withoutValue =
                  accumulator.withoutValue.concat(currentValue);
            } else {
              accumulator.withValue = accumulator.withValue.concat(currentValue);
            }

            return accumulator;
          },
          {withValue: [], withoutValue: []}
      );
      return {withValue, withoutValue};
    },
    sortHotels() {
      let hotels = [];
      hotels = this.filteredHotels;

      switch (this.selectfilter.sortType) {
        case "price": {
          const {withValue, withoutValue} = this.separateNull(hotels);
          if (this.selectfilter.sortDirection == "asc") {
            withValue.sort((a, b) => {
              const propositionsHotelA = this.removeNullValue(a).sort()[0];
              const propositionsHotelB = this.removeNullValue(b).sort()[0];
              return propositionsHotelA - propositionsHotelB;
            });

            hotels = withValue.concat(withoutValue);
          } else if (this.selectfilter.sortDirection == "desc") {
            withValue.sort((a, b) => {
              const propositionsHotelA = this.removeNullValue(a).sort(
                  (a, b) => b - a
              )[0];
              const propositionsHotelB = this.removeNullValue(b).sort(
                  (a, b) => b - a
              )[0];
              return propositionsHotelB - propositionsHotelA;
            });

            hotels = withValue.concat(withoutValue);
          }
          break;
        }
        case "rating": {
          if (this.selectfilter.sortDirection == "asc") {
            hotels.sort((a, b) => {
              const aValue = a.hotel.rating ? a.hotel.rating.value : 0
              const bValue = b.hotel.rating ? b.hotel.rating.value : 0
              return aValue - bValue
            })
          } else {
            hotels.sort((a, b) => {
              const aValue = a.hotel.rating ? a.hotel.rating.value : 0
              const bValue = b.hotel.rating ? b.hotel.rating.value : 0
              return bValue - aValue
            });
          }
          break;
        }
      }
      return (this.filteredHotels = hotels);
    }
    ,
  },
};
</script>
<style lang="less"></style>
