<template>
  <widget-container
    :url="url"
    cardclass="ma-0 pa-1"
    :bus="bus"
    :flat="true"
    :dark="false"
    :alwaysrender="!loading"
    @ready="setData"
  >
    <template slot="content">
      <v-card flat>
        <apexchart
          v-if="series.length > 0 && chartOptions.chart.labels.length > 0"
          ref="apexcharts"
          type="area"
          height="450"
          :series="series"
          :options="chartOptions.chart"
        ></apexchart>
        <div v-else>Aucun résultats</div>
      </v-card>
    </template>
  </widget-container>
</template>
<script>
import WidgetContainer from "./WidgetContainer";
export default {
  name: "WidgetAgenciesResume",
  components: { WidgetContainer },
  props: {
    bus: {
      type: Object,
      required: true,
    },
    url: {
      type: String,
      required: false,
      default: "",
    },
  },
  data() {
    return {
      loading: false,
      data: null,
      chartOptions: {
        chart: {
          chart: {
            stacked: false,
            toolbar: {
              show: true,
              tools: {
                download: true,
                selection: true,
                zoom: false,
                zoomin: false,
                zoomout: false,
                pan: false,
                reset: false,
              },
            },
          },
          width: "100%",
          type: "area",
          labels: [],
          xaxis: {
            type: "datetime",
          },
          yaxis: [
            {
              title: {
                text: "Nb Agences",
              },
            },
          ],
          stroke: {
            show: true,
            width: 2,
          },
          dataLabels: {
            enabled: true,
          },
          tooltip: {
            enabled: false,
          },
        },
      },
    };
  },
  created() {
    this.$nextTick(() => {
      this.bus.$on("search", () => {
        this.loading = true;
        this.$emit("loading", true);
      });
    });
  },
  destroyed() {
    this.bus.$off("search");
  },
  methods: {
    setData(data) {
      this.data = data;
      this.chartOptions.chart.labels =
        this.data && this.data.innactive
          ? this.data.innactive.map((d) => d[1])
          : [];
      this.loading = false;
      this.$emit("loading", false);
    },
    getSerie(rawSerie) {
      return rawSerie.map((d) => d[0]);
    },
  },
  computed: {
    innactiveSerie() {
      return this.data && this.data.innactive
        ? this.getSerie(this.data.innactive)
        : [];
    },
    activeSerie() {
      return this.data && this.data.active
        ? this.getSerie(this.data.active)
        : [];
    },
    curiousSerie() {
      return this.data && this.data.curious
        ? this.getSerie(this.data.curious)
        : [];
    },
    usedToSerie() {
      return this.data && this.data.usedTo
        ? this.getSerie(this.data.usedTo)
        : [];
    },

    series() {
      return [
        {
          name: "Inactif",
          data: this.innactiveSerie,
        },
        {
          name: "Actif",
          data: this.activeSerie,
        },
        {
          name: "Curieux",
          data: this.curiousSerie,
        },
        {
          name: "Habitué",
          data: this.usedToSerie,
        },
      ];
    },
  },
};
</script>
